import React from "react";
import { AccordionFaq } from "./AccordionFaq";

const questions = [
  "¿Cómo puedo solicitar un préstamo?",
  "¿Cuáles son los requisitos para solicitar un préstamo?",
  "¿Necesito presentar algún documento para obtener el préstamo?",
  "¿Qué monto puedo solicitar?",
  "¿Cuáles son los plazos de préstamo disponibles para mí?",
  "¿Qué hago si necesito más dinero?",
  "¿Cómo puedo recibir mi dinero?",
  "¿Por qué rechazaron mi solicitud?",
  "¿Cómo puedo ingresar a mi perfil personal de Vivus.com.mx?",
  "¿Qué debo hacer en caso de que olvide la contraseña?",
  "¿Cómo pagar mi préstamo?",
  "¿Puedo pagar mi préstamo antes del vencimiento?",
  "¿Qué puedo hacer si no puedo pagar mi préstamo en tiempo?",
  "¿Cómo puedo cambiar mi información personal?",
  "¿En cuanto tiempo dan respuesta a mi solicitud?",
  "¿Con quién puedo hablar en caso de no encontrar respuestas a mis dudas?",
  "¿Puedo solicitar factura de los intereses pagados de mi préstamo?",
];

const answers = [
  "Si eres cliente nuevo da clic en Solicítalo Ya y completa la solicitud del préstamo. Una vez registrado para ingresar a tu perfil deberás seleccionar ¿Ya eres cliente?, elige el monto, así como plazo de tu conveniencia y sigue las instrucciones. Cualquier duda, por favor, comunícate con nuestro Equipo de Servicio al Cliente mediante WhatsApp o bien, llamando al (55) 6717 0750 de lunes a viernes de 8:00-20:00 / Sábado de 8:00 a 14:00 o contáctanos en info@vivus.com.mx",
  "Para obtener el préstamo debes: 1) Ser ciudadano mexicano(a) 2) Ser mayor de 18 años 3) Ser empleado, profesionista independiente o contar con ingreso regular  4) Tener un teléfono celular y un correo electrónico 5) Completar toda la información requerida en la solicitud de préstamo 6) Enviar la documentación solicitada.",
  "Debes subir las fotos (frente y reverso) de tu identificación oficial (IFE/INE), en formato de imagen, por favor no envíes PDF. Puedes hacerlo a través de tu perfil en el portal Vivus.com.mx, WhatsApp o bien, enviarla a documentos@Vivus.com.mx.",
  "Puedes solicitar un préstamo desde 1,000 pesos y hasta un máximo de 10,000 pesos. El monto disponible es determinado en forma individual para cada uno de nuestros clientes. Puedes revisar tu límite de préstamo disponible ingresando a tu perfil en vivus.com.mx",
  "Puedes solicitar un préstamo por un plazo para pagar de 62 días a 360 días",
  "Una vez que has solicitado tu préstamo puedes ingresar a tu perfil en vivus.com.mx y solicitar un monto adicional siempre y cuando no sobrepases tu límite de crédito. Si tu límite ha sido alcanzado debes realizar el pago de liquidación y solicitar un nuevo préstamo.",
  "Con Vivus puedes recibir tu dinero fácil y sencillo: Por transferencia, directamente a tu cuenta bancaria mediante el uso de CLABE o Número de tarjeta de débito.",
  "Todas las solicitudes están sujetas a verificación. Cada solicitud debe cumplir con todos los requisitos siguiendo nuestras normas de crédito internas y externas. Tu solicitud fue rechazada posiblemente debido a que no cumplió con estos requisitos.",
  'Si ya eres cliente y ya tienes un perfil, haz clic en el botón ¿Ya eres cliente?, en la parte superior derecha de la página de inicio. Una vez ahí, sólo debes ingresar tu dirección de correo electrónico, contraseña y listo. Si eres cliente nuevo, selecciona el monto y plazo que deseas solicitar en la calculadora Vivus y haz clic en el botón "Solicítalo ya", automáticamente serás direccionado a la página de Registro. Una vez ahí completa los campos necesarios para registrarte y crear tu perfil personal.',
  'En caso de que hayas olvidado tu contraseña, por favor haz clic en el enlace ¿Ya eres cliente?, en la esquina superior derecha de la página web. Después haz clic en el enlace "¿Olvidó su contraseña?" y sigue los pasos. Tu nueva contraseña temporal y las instrucciones sobre qué hacer a continuación serán enviados a tu dirección de correo electrónico. Toma en cuenta que la contraseña temporal tendrá una vigencia de sólo una semana. Por lo tanto, asegúrate de cambiarla desde tu perfil por una permanente. Por tu seguridad, la contraseña temporal no se puede utilizar cuando se toma un préstamo, ya que se debe cambiar a una contraseña permanente previamente.',
  `Tenemos estas opciones de pago para ti, disponibles desde tu perfil Vivus: Pago en establecimientos utilizando la modalidad de Pay Cash y Oxxo Pay, con tarjeta de débito o bien, en bancos. Toda esta información la encuentras en tu perfil, ingresando con tu correo electrónico y contraseña.`,
  "Si, puedes pagarlo en cualquier momento antes del vencimiento del plazo, sin ninguna penalización y cubriendo sólo los intereses devengados hasta ese momento.",
  "Si crees que necesitas más tiempo para pagar tu préstamo, por favor, ingresa a tu perfil en vivus.com.mx y consulta la opción de recorrer la fecha de pago de tu préstamo.",
  "Ingresa a tu perfil en vivus.com.mx y solicita el cambio de la información que necesites. Por seguridad, te haremos llegar un código PIN a tu correo o celular a fin de validar cualquier cambio. ",
  "Si tu solicitud fue pre aprobada y la información que registraste, así como los documentos que nos envías son correctos y cumplen con las características requeridas, te damos respuesta en minutos sobre tu solicitud y en caso de ser aprobada, comenzaremos el proceso para que recibas tu dinero.",
  "Puedes contactar a nuestro Equipo de Servicio al Cliente llamando al (55) 6717 0750 de Lunes a Viernes de 8:00 -20:00 hrs. O los sábados y domingos de 8:00 a 14:00 hrs.",
  "Sí, puedes solicitar tu factura por concepto de intereses escribiéndonos al correo facturacion@digitalfinance.com especificándonos todos tus datos.",
];

export const FAQ = () => {
  return (
    <div className="flex justify-center items-center flex-1 flex-col w-full">
      <div className="container px-4 py-12 flex gap-4 flex-col">
        <h2 className="lg:text-4xl text-3xl font-bold">Preguntas Frecuentes</h2>
        <div className="flex flex-col gap-2">
          {questions.map((question, index) => (
            <AccordionFaq question={question} answer={answers[index]} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
