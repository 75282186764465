import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TagManager from "react-gtm-module";

const AFFILIATES = [{ name: "Credy", img: "/img/logo-credy.svg", url: "https://tracking.adcredy.com/SH17g" }];

export const NotApproved = () => {
  const [affiliate, setAffiliate] = useState(AFFILIATES[0]);
  const [email] = useState(sessionStorage.getItem("user-email") || "");

  //TAG MANAGER
  const tagManagerArgs = {
    dataLayer: {
      event: "pageChange",
      page: {
        url: "/rejected",
        referrer: sessionStorage.getItem("utm") || "/",
      },
    },
    dataLayerName: "dataLayer",
  };
  TagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    setAffiliate(AFFILIATES[0]);
  }, []);

  //TAG MANAGER
  return (
    <>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-8 px-4 py-12 self-center">
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Disculpa, lamentablemente no podemos ofrecerte</h1>
            <h2 className="text-2xl lg:text-4xl text-green-light">un préstamo en este momento.</h2>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-xl lg:text-2xl text-green-dark">Razones comunes por las cuales un crédito es rechazado:</h3>
            <ul>
              <li className="text-md flex gap-4 items-center">
                <FontAwesomeIcon icon={faTimes} />
                <span>No se pudo consultar tu historial crediticio.</span>
              </li>
              <li className="text-md flex gap-4 items-center">
                <FontAwesomeIcon icon={faTimes} />
                <span>No se pudo verificar tu identidad.</span>
              </li>
              <li className="text-md flex gap-4 items-center">
                <FontAwesomeIcon icon={faTimes} />
                <span>Tu nivel de endeudamiento es actualmente alto.</span>
              </li>
              <li className="text-md flex gap-4 items-center">
                <FontAwesomeIcon icon={faTimes} />
                <span>Tienes adeudos pendientes con otras compañías.</span>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-xl lg:text-2xl text-green-dark">Cosas que puedes hacer:</h3>
            <p>
              Es fundamental que revises tu historial crediticio. Verifica que tus <strong>datos personales</strong> sean correctos, especialmente tu <strong>RFC</strong> y <strong>domicilio</strong>. Asegúrate que todos los datos específicos sobre alguna deuda en el pasado estén actualizados
            </p>
            <div className="py-8 px-4 gap-4 flex flex-col justify-center items-center text-center border-2 rounded-xl border-blue-600  lg:w-1/3 w-full">
              <img src={affiliate.img} alt="Logo Afiliado" width={affiliate.name === "Credy" ? 150 : 250} />
              <h4 className="text-xl text-blue-600">¡No te preocupes!</h4>
              <p>Aún puedes obtener el préstamo que necesitas</p>
              <a href={affiliate.url} className="bg-blue-600 cursor-pointer rounded-xl p-4 w-32 text-center items-center justify-center font-bold uppercase">
                Aplicar
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-xl lg:text-2xl text-green-dark">Nos mantendremos en contacto</h3>
            <p>
              Nos encantaría poder otorgarte un préstamo en el futuro. Si crees que esta respuesta es incorrecta, si tu situación cambia o sospechas que existe algún error en tu historial de crédito que puede ser aclarado con la institución que te reporta, por favor contáctanos nuevamente y te
              atenderemos con gusto.
            </p>
            <strong className="text-xl">Mucha suerte. El equipo de {process.env.REACT_APP_SITE === "vivus" ? "vivus.com.mx" : "www.mivivus.com"}</strong>
            {/* {process.env.REACT_APP_SITE === "mivivus" && (
              <a href="https://alprestamo.mx/?utm_medium=rec&utm_source=vivus01" target="_blank">
                <img src={"/img/banner-rejected.jpeg"} alt="Banner afiliado" className="w-full lg:w-1/4 my-4" />
              </a>
            )} */}
            <input type="hidden" name="eMail" id="email" value={email} />
          </div>
        </div>
      </div>
    </>
  );
};
