import publicIp from "public-ip";

export const getIp = async () => {
  try {
    const ip = sessionStorage.ip ? sessionStorage.ip : await publicIp.v4();
    if (ip) {
      sessionStorage.setItem("ip", ip);
    } else {
      sessionStorage.setItem("ip", "Unable to get IP address");
    }
    return ip;
  } catch (e) {
    sessionStorage.setItem("ip", "Unable to get IP address");
  }
};

export const refreshIp = async () => {
  try {
    const ip = await publicIp.v4();
    if (ip) {
      sessionStorage.setItem("ip", ip);
    } else {
      sessionStorage.setItem("ip", "Unable to get IP address");
    }
  } catch (e) {
    sessionStorage.setItem("ip", "Unable to get IP address");
  }
};
