import React from "react";

const Legal = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="container flex-col py-12 px-4">
        <div className={`overflow-y-hidden transition-all flex flex-col gap-4 text-xs text-left text-gray-500`}>
          <p>VIVUS es un producto de DIFINANCE , S.A. de C.V., SOFOM, E.N.R. y es una marca protegida y registrada bajo las leyes mexicanas. Todos los derechos reservados.</p>
          <p>
            Recuerda que VIVUS no opera con intermediarios y que la solicitud de tu crédito debes llevarla a cabo de manera personal de conformidad con nuestros Términos y Condiciones, así como con las leyes y reglamentos aplicables, por lo que nos deslindamos expresamente de cualquier
            responsabilidad derivada de una solicitud realizada en incumplimiento a lo anterior incluyendo, sin limitar, que un tercero solicite un crédito a tu nombre.
          </p>
          <p>
            DIFINANCE , S.A. de C.V., SOFOM, E.N.R., se encuentra en Ceres 23, Crédito Constructor, 03940, Benito Juárez, Ciudad de México. Para mayor información, contactar a info@vivus.com.mx. Según las normas aplicables a DIFINANCE , S.A. de C.V., SOFOM, E.N.R., No requiere de autorización de la
            Secretaria de Hacienda y Crédito Público para organizarse y operar como una Sociedad Financiera de Objeto Múltiple, Entidad No Regulada, y está sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores únicamente para efectos del artículo 56 de la Ley General de
            Organizaciones y Actividades Auxiliares del Crédito.
          </p>
          <p>
            El plazo del crédito quedará establecido conforme a lo pactado entre el cliente y DIFINANCE, el cual puede variar entre 62 a 90 días. Los créditos personales podrán ser adquiridos en toda la República Mexicana. No existen renovaciones automáticas de créditos. Cualquier préstamo o monto
            adicional otorgado por DIFINANCE deberá ser solicitado por el cliente, quien deberá aceptar explícitamente las condiciones de montos y plazos previamente. El término Comisiones se refiere a Comisión por apertura 4%; Comisión por Disposición del Crédito 1%; Comisión por Gastos de
            Investigación 2.5%, más IVA cada una. Todas las comisiones son calculadas sobre el monto del crédito.{" "}
            <a href="/contenido/terminos-y-condiciones-plazos" className="text-green-dark font-bold underline">
              Aplican restricciones
            </a>
          </p>
          <p>
            <span className="bold-type type-up"> *Sujeto a aprobación de:</span> crédito, que la información y fotografías solicitadas cumplan con los requisitos y de acuerdo a las políticas internas de aprobación.
          </p>

          <p>
            <span className="bold-type type-up">CAT:</span> la tasa de interés mensual ordinaria aplicable es de 42.58% más el IVA. Por defecto, la tasa de interés anual fija es de 511.0% más el IVA más Comisión por apertura 4%; Comisión por Disposición del Crédito 1%; Comisión por Gastos de
            Investigación 2.5%, más IVA cada una. O un Costo Anual Total (CAT) PROMEDIO: 8969.90% sin IVA por $10,000 M.N. calculado sobre un préstamo de 90 días. Calculado al día 25 de marzo de 2024.
          </p>
          <p>
            Un ejemplo de préstamos de dinero en línea en la plataforma de créditos rápidos en línea Vivus México es un préstamo de $10,000 pesos (Diez mil pesos MN) a 62 días, con pagos digitales totales de $20,938.80 pesos MN, incluyendo IVA de 1,508.80 pesos MN. CAT informativo: 12,961.4%%. Tasa
            de interés anual préstamos de dinero en línea y préstamos personales: 511% sin IVA
          </p>
          <p>
            Entre las implicaciones que podrían resultar de la falta de pago están: (1) Intereses moratorios a una tasa anual fija de
            <span className="bold-type type-up"> 730%</span> equivalente al <span className="bold-type type-up">2%</span> diario, (2) Gestiones de cobranza al teléfono y domicilio del cliente, (3) Afectación del historial de crédito, (4) Implicaciones legales derivadas del incumplimiento del
            contrato.
          </p>
          <p>
            El Buró de Entidades Financieras es una herramienta de consulta y difusión con la que podrás conocer los productos que ofrecen las entidades financieras, sus comisiones y tasas, las reclamaciones de los usuarios, las prácticas no sanas en que incurren, las sanciones administrativas que
            les han impuesto, las cláusulas abusivas de sus contratos y otra información que resulte relevante para informarte sobre su desempeño. Con el Buró de Entidades Financieras, se logrará saber quién es quién en bancos, seguros, sociedades financieras de objeto múltiple, cajas de ahorro,
            afores, entre otras entidades. Con ello, podrás comparar y evaluar a las entidades financieras, sus productos y servicios y tendrás mayores elementos para elegir lo que más te convenga. Esta información te será útil para elegir un producto financiero y también para conocer y usar mejor
            los que ya tienes. Este Buró de Entidades Financieras, es una herramienta que puede contribuir al crecimiento económico del país, al promover la competencia entre las instituciones financieras; que impulsará la transparencia al revelar información a los usuarios sobre el desempeño de
            éstas y los productos que ofrecen y que va a facilitar un manejo responsable de los productos y servicios financieros al conocer a detalle sus características.
          </p>
          <div className="w-full overflow-auto flex">
            <img src={"/img/buro.png"} alt="tabla buró" className="object-contain flex min-h-[300px] min-w-[1000px]" />
          </div>
          <p>La información antes señalada corresponde únicamente a DIFINANCE , S.A. de C.V., SOFOM, E.N.R. y, para conocer la información de todo el sector a la que corresponda, se podrá acceder al sitio http://www.buro.gob.mx.</p>
          <div className="text-lg font-bold">
            UNE de Atención a Usuarios
            <br />
            En caso de alguna consulta, reclamación o aclaración, podrá presentar en la UNE de Atención a Usuarios, la que dará respuesta en un plazo no mayor a 30 días hábiles.
            <br />
            <span className="font-light">Titular de la UNE:</span> FERNANDO DEL RAZO LÓPEZ <br />
            <span className="font-light">Domicilio:</span> CERES NO. 23, COLONIA CRÉDITO CONSTRUCTOR C.P. 03940 ALCALDÍA/MUNICIPIO/ BÉNITO JUÁREZ ENTIDAD FEDERATIVA: CIUDAD DE MÉXICO, Referencia: NOTARIAS 6 Y 142 ENTRE CALLE AV. INSURGENTES SUR Y CALLE MINERVA.
            <br />
            <span className="font-light">Horario de atención:</span> Lunes a Viernes de 9:00 a 17:00 Hrs. <br />
            <span className="font-light">Teléfono y correo electrónico: </span>5585266148 | une@digitalfinance.com
            <br />
            <br />
            <span className="font-light">Encargados regionales:</span>
            <div className="grid grid-cols-1 lg:grid-cols-4 my-2 gap-2">
              <div className="text-green-dark row-start-1 lg:col-start-1">Entidades federativas / Municipios</div>
              <div className="text-green-dark row-start-3 lg:row-start-1 lg:col-start-2">Encargado (s)</div>
              <div className="text-green-dark row-start-5 lg:row-start-1 lg:col-start-3">Domicilio (s)</div>
              <div className="text-green-dark row-start-7 lg:row-start-1 lg:col-start-4">Teléfono (s) y correo (s) electrónico (s)</div>
              <div className="row-start-2 lg:row-start-2 lg:col-start-1">Ciudad de México</div>
              <div className="row-start-4 lg:row-start-2 lg:col-start-2">FERNANDO DEL RAZO LÓPEZ</div>
              <div className="row-start-6 lg:row-start-2 lg:col-start-3">CERES NO. 23, COLONIA CRÉDITO CONSTRUCTOR C.P. 03940 ALCALDÍA/MUNICIPIO/ BÉNITO JUÁREZ ENTIDAD FEDERATIVA: CIUDAD DE MÉXICO, Referencia: NOTARIAS 6 Y 142 ENTRE CALLE AV. INSURGENTES SUR Y CALLE MINERVA.</div>
              <div className="row-start-8 lg:row-start-2 lg:col-start-4">Tel. 5585266148 / une@digitalfinance.com</div>
            </div>
            El horario de atención de los Encargados Regionales es: Lunes a Viernes de 9:00 a 17:00 Hrs.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legal;
