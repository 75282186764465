import React, { lazy } from "react";
import TagManager from "react-gtm-module";
import { Suspense } from "react/cjs/react.production.min";

const LinkButton = lazy(() => import("../common/LinkButton"));

const tagManagerArgs = {
  dataLayer: {
    event: "pageChange",
    page: {
      url: "/us",
      referrer: sessionStorage.getItem("utm") || "/",
    },
  },
  dataLayerName: "dataLayer",
};

export const AboutUs = () => {
  TagManager.dataLayer(tagManagerArgs);
  return (
    <Suspense fallback={<span>Cargando...</span>}>
      <div className="flex justify-center w-full items-center flex-1 flex-col ">
        <div className="container px-4 py-24 flex gap-4 flex-col">
          <div className="flex w-full my-4 box-border items-center justify-center flex-col lg:flex-row gap-4">
            <div className="text-left lg:w-1/2 xl:w-5/12 flex flex-col gap-4">
              <h2 className="text-4xl leading-10 lg:text-5xl font-bold bg-green-dark text-white px-4 w-fit py-8">DIFINANCE , S.A. de C.V., SOFOM, E.N.R.</h2>
              <p className="w-fit text-lg">
                El principal objetivo de DIFINANCE , S.A. de C.V., SOFOM, E.N.R. es ofrecer a nuestros clientes una alternativa de financiamiento fácil, rápida y responsable al momento de cubrir imprevistos. Con esta visión en mente, trabajamos día a día para dar un trato excepcional a quienes
                confían en nosotros, siendo la transparencia nuestro principal pilar de servicio.
              </p>
            </div>
            <div className="flex lg:w-1/2 xl:w-7/12 justify-center">
              <img src="/img/landing/isotipo.svg" alt="logo" width={250} />
            </div>
          </div>
        </div>
        <div className="bg-green-light transform -skew-y-3 py-16 w-full flex justify-center">
          <div className="container flex skew-y-3 flex-col gap-8 p-4">
            <div className="text-white text-3xl font-bold">Los principios que rigen nuestro trabajo son:</div>
            <div className="flex justify-around w-full text-white items-center flex-col lg:flex-row gap-8">
              <div className="flex flex-col gap-4 items-center justify-center text-center max-w-md">
                <img src="/img/aboutUs/icon-people.svg" width={150} alt="percent" />
                <h2 className="text-xl font-bold">Satisfacción del cliente</h2>
                <p>Queremos resolver una necesidad de dinero urgente con la mayor rapidez, eficacia y buen trato.</p>
              </div>
              <div className="flex flex-col gap-4 items-center justify-center text-center max-w-md">
                <img src="/img/aboutUs/icon-thumbs.svg" width={115} alt="feat" />
                <h2 className="text-xl font-bold">Transparencia</h2>
                <p>Recuerda que nuestra tasa de interés siempre es visible, nuestros préstamos son sin comisiones, letras chiquitas o costos ocultos. El único requisito es pagarlo en un lapso de 1 a 3 meses.</p>
              </div>
              <div className="flex flex-col gap-4 items-center justify-center text-center max-w-md">
                <img src="/img/aboutUs/icon-shield.svg" width={110} alt="shield" />
                <h2 className="text-xl font-bold">Responsabilidad</h2>
                <p>Sugerimos a nuestros clientes que pidan un préstamo rápido, solo si es su mejor opción de financiamiento.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-200 -skew-y-3 w-full py-28 gap-8 flex items-center flex-col">
          <div className="container p-4 flex-col gap-8 skew-y-3">
            <div className="flex items-center gap-8 flex-col  lg:flex-row">
              <div className="flex items-start gap-8 flex-col text-left justify-center w-full lg:w-1/2">
                <h2 className="lg:text-5xl text-4xl font-bold">¿Qué ofrecemos?</h2>
                <p className="text-lg">Vivus ofrece un préstamo rápido, eficaz y que se adapta a tu estilo de vida. El importe máximo de nuestros créditos es de $10,000 pesos para clientes existentes, con un plazo de 62 días.</p>
                <p className="text-lg">Pedir un préstamo Vivus es muy fácil: solamente es necesario llenar el formulario en línea y en instantes nosotros analizamos las solicitudes y respondemos. Todas las solicitudes están sujetas a los términos y condiciones de los préstamos.</p>
              </div>
              <div className="w-full lg:w-1/2 flex justify-center items-center">
                <img src="/img/wallet.svg" alt="logo" className="lg:w-72 w-56" />
              </div>
            </div>
          </div>
          <div className="container p-4 flex-col skew-y-3">
            <div className="flex items-center gap-8 flex-col w-full lg:flex-row">
              <div className="flex flex-col text-lg gap-8 w-full lg:w-1/2 ">
                <h2 className="lg:text-5xl text-4xl font-bold">Las ventajas de Vivus</h2>
                <p className="flex gap-4">
                  <img src="/img/bulletCheck.svg" width={32} alt="bullet" />
                  Nuestros préstamos son accesibles desde cualquier lugar de México, solo se requiere conexión a Internet.
                </p>
                <p className="flex gap-4">
                  <img src="/img/bulletCheck.svg" width={32} alt="bullet" />
                  Nuestros servicios de transferencia están disponibles las 24 horas los 7 días de la semana (para clientes existentes).
                </p>
                <p className="flex gap-4">
                  <img src="/img/bulletCheck.svg" width={32} alt="bullet" />
                  El registro es fácil y rápido. En menos de 5 minutos te damos una respuesta a tu solicitud.
                </p>
                <p className="flex gap-4">
                  <img src="/img/bulletCheck.svg" width={32} alt="bullet" />
                  Los datos personales son totalmente confidenciales y son tratados con la máxima seguridad.
                </p>
                <p className="flex gap-4">
                  <img src="/img/bulletCheck.svg" width={32} alt="bullet" />
                  El depósito se realiza a tu cuenta registrada en menos de 1 hora una vez aprobado.
                </p>
              </div>
              <div className="w-full lg:w-1/2  flex justify-center items-center">
                <img src="/img/umbrella.svg" alt="logo" className="w-56 lg:w-72" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-green-dark z-10 py-32 -mt-16 flex justify-center">
          <div className="w-full container flex flex-col justify-center items-center text-white gap-4 p-4 text-center">
            <h2 className="text-3xl lg:text-4xl text-bold">Solicita tu primer préstamo con nosotros en minutos.*</h2>
            <p className="text-xl">Conoce desde el inicio cuánto debes pagar</p>
            <LinkButton className="text-xl px-12 max-w-md border-transparent border-4 hover:border-green-light" to="/registration">
              Solicítalo ya
            </LinkButton>
          </div>
        </div>
      </div>
    </Suspense>
  );
};
