import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Helmet } from "react-helmet";

const tagManagerArgs = {
  dataLayer: {
    event: "pageChange",
    page: {
      url: "/terminos-y-condiciones",
      referrer: sessionStorage.getItem("utm") || "/",
    },
  },
  dataLayerName: "dataLayer",
};
const TerminosCondiciones = () => {
  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Términos y condiciones | Vivus Mx</title>
        <meta name="title" content="Términos y condiciones | Vivus Mx" />
        <meta name="description" content="¡Consigue dinero rápido con nuestros préstamos en línea! Solicita ahora. ¿Necesitas dinero en efectivo? Solicita nuestro préstamo digital en minutos." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-4 p-4 max-w-4xl">
          <div className="flex flex-col gap-4 py-4">
            {/* <DemoButton callback={fillDemo} /> */}
            <div className="w-full flex flex-col gap-2">
              <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Términos y condiciones</h1>
            </div>

            <p>
              DIFINANCE, S.A. de C.V., SOFOM, E.N.R. (EN LO SUCESIVO “VIVUS”), CON DOMICILIO EN: CALLE CERES 23, CRÉDITO CONSTRUCTOR, C.P.03940, BENITO JUAREZ, CIUDAD DE MÉXICO. INFORMA POR ESTE MEDIO QUE ESTA PÁGINA ELECTRÓNICA (EN LO SUCESIVO “PÁGINA”), CONTIENE DE MANERA PUNTUAL MÁS NO LIMITANTE,
              SERVICIOS, INFORMACIÓN, PRODUCTOS Y CONSULTAS PRINCIPALMENTE DE NATURALEZA FINANCIERA.{" "}
            </p>
            <p>ES OBLIGACIÓN Y RESPONSABILIDAD DEL “USUARIO” LEER Y ACEPTAR LOS SIGUIENTES TÉRMINOS Y CONDICIONES ANTES DE UTILIZAR LOS SERVICIOS DE “VIVUS”.</p>

            <h2 className="text-xl font-bold">a) RESPONSABILIDADES DEL “USUARIO”</h2>
            <p>
              LOS SERVICIOS Y CONTENIDOS DE LA “PÁGINA” PODRÁN SER UTILIZADOS ÚNICAMENTE POR LAS PERSONAS QUE TENGAN CAPACIDAD LEGAL DE HACERLO (EN LO SUCESIVO LOS “USUARIOS”) Y QUE NO HAYAN SIDO SUSPENDIDOS O INHABILITADOS PERMANENTEMENTE DE “VIVUS”. AL UTILIZAR LA "PÁGINA", EL “USUARIO” ACEPTA POR
              COMPLETO LOS PRESENTES TÉRMINOS Y CONDICIONES. POR SU PARTE “VIVUS” SE RESERVA EL DERECHO DE NEGAR O SUSPENDER SUS SERVICIOS Y CONTENIDOS AL “USUARIO”, PUDIENDO DE IGUAL MANERA CAMBIAR LOS SERVICIOS Y CONTENIDOS DE LA “PÁGINA” SIN PREVIO AVISO.{" "}
            </p>
            <p>
              “VIVUS” NO GARANTIZA EN SU “PÁGINA” Y/O SERVICIOS LA DISPONIBILIDAD, UTILIDAD, Y CONTINIUDAD DE SUS OPERACIONES EN RELACIÓN CON NINGUNA ACTIVIDAD ESPECÍFICA. ASI MISMO “VIVUS” NO SE HACE RESPONSABLE POR DAÑO O PÉRDIDA ALGUNA COMO RESULTADO DE LA DISPONIBILIDAD Y CONTINUIDAD DE LAS
              OPERACIONES DE LA “PÁGINA”.
            </p>

            <h2 className="text-xl font-bold">b) CONFIDENCIALIDAD</h2>
            <p>
              ES OBILIGACIÓN DE “VIVUS” MANTENER LA CONFIDENCIALIDAD DE LA INFORMACIÓN QUE RECIBA POR PARTE DEL “USUARIO” QUE TENGA DICHO CARÁCTER DE ACUERDO CON LA LEGISLACIÓN QUE SE APLICA A LA MATERIA EN LA REPUBLICA MEXICANA. NO ES RESPONSABILIDAD DE “VIVUS” MANTENER DE MANERA CONFIDENCIAL
              CUALQUIER OTRO TIPO DE INFORMACIÓN ENVIADA POR EL “USUARIO” EN CUALQUIER INTERACCION EN LA “PÁGINA” O DURANTE LA ADQUISICION DE LOS SERVICIOS Y CONTENIDOS Y/O EN EL PROCESO DE APLICACIÓN. INCLUIDA, AUNQUE NO LIMITADA, LA INFORMACIÓN DADA EN LOS CHATS, BLOGS, ETC.{" "}
            </p>
            <p>
              POR SU PARTE EL “USUARIO” DA AUTORIZACIÓN A “VIVUS” DE PUBLICAR, USAR Y COMUNICAR DE MANERA PÚBLICA LA INFORMACIÓN QUE NO SE CONSIDERE CONFIDENCIAL INGRESADA POR MEDIO DE LA “PÁGINA” DE ACUERDO CON LO QUE ESTABLECE LA LEY EN EL ARTÍCULO 109 DE LA LEY FEDERAL DE LOS DERECHOS DE AUTOR.{" "}
            </p>

            <h2 className="text-xl font-bold">c) USO DE INFORMACIÓN PERSONAL</h2>
            <p>
              LA INFORMACIÓN PERSONAL QUE SEA RECABADA POR “VIVUS” SERÁ USADA PARA EL REGISTRO Y OPERACIÓN DE LOS SERVICIOS Y CONTENIDOS. EL “USUARIO” SE COMPROMETE A BRINDAR SU INFORMACIÓN LEGÍTIMA Y EXACTA DE ACUERDO CON EL FORMULARIO DE REGISTRO, ASI COMO A ACTUALIZAR SUS DATOS CUANDO SEA
              NECESARIO. EN CASO DE SER NECESARIO “VIVUS” SE RESERVA EL DERECHO DE SOLICITAR EL O LOS COMPROBANTES NECESARIOS PARA CONFIRMAR LA INFORMACIÓN.
            </p>

            <h2 className="text-xl font-bold">d) PROPIEDAD INTELECTUAL</h2>
            <p>
              EN MÉXICO Y EN EL EXTRANJERO “VIVUS” ES UNA MARCA REGISTRADA Y ESTÁ PROTEGIDA POR LA LEY DE PROPIEDAD INTELECTUAL SEGÚN LAS LEYES APLICABLES EN CADA PAÍS. POR LO TANTO, LA DIFUSIÓN, USO, COMERCIALIZACIÓN, EXPLOTACIÓN O CUALQUIER OTRO USO YA SEA TOTAL O PARCIAL, DE FORMA EXACTA O QUE
              CAUSE CONFUSIÓN DE MAYOR O MENOR GRADO, SIN IMPORTAR EL MEDIO, INCLUÍDO Y NO LIMITADO AL IMPRESO, ELECTRÓNICO, ÓPTICO, O INFORMÁTICO, ESTA EXPLÍCITAMENTE PROHIBIDO SIN AUTORIZACIÓN ESCRITA PREVIA DEL TITULAR DE LOS DERECHOS DE MARCA Y/O DE AUTOR. CUALQUIER INCUMPLIMIENTO A LA
              LEGISLACIÓN APLICABLE EN LO REFERENTE A PROPIEDAD INDUSTRIAL, INTELECTUAL Y DERECHOS DE AUTOR, O A LO MENCIONADO ANTERIORMENTE SE CONSIDERARÁ UN DELITO Y SERÁ JUZGADO CONFORME A LA LEY. TODOS LOS CONTENIDOS, LOGOTIPO, DISEÑO, IMAGEN, FORMAS, MARCA, ASÍ COMO CUALQUIER OTRO MATERIAL
              INCLUÍDO EN LA "PÁGINA", SERVICIOS, PROGRAMAS, REDES, ARCHIVOS, SON PROPIEDAD DE “VIVUS”, POR LO TANTO, QUEDA EXTRICTAMENTE PROHIBIDO EL USO PARCIAL O TOTAL DE CUALQUIERA DE LOS MATERIALES PREVIAMENTE MENCIONADOS. RESERVANDOSE LOS DERECHOS CORRESPONDIENTES DE PROPIEDAD INTELECTUAL DE
              USO Y EXPLOTACIÓN, INCLUYENDO LA REPRODUCCIÓN, EXPLOTACIÓN, DIVULGACIÓN, TRANSFORMACIÓN Y DISTRIBUCIÓN, ÚNICAMENTE A “VIVUS”.{" "}
            </p>
            <p>EL “USUARIO” NO ADQUIERE POR NINGÚN MOTIVO Y BAJO NINGUNA CIRCUNSTANCIA DERECHO O CONSESIÓN ALGUNA RESPECTO DE LA PROPIEDAD INTELECTUAL E INDUSTRIAL DE “VIVUS” POR EL SIMPLE USO DE LA “PÁGINA” O DE SUS SERVICIOS Y CONTENIDOS.</p>

            <h2 className="text-xl font-bold">e) PROHIBICIONES</h2>
            <p>
              QUEDA ESTRICTAMENTE PROHIBIDO QUE EL “USUARIO” CEDA, VENDA O TRANSFIERA TOTAL O PARCIALMENTE SU CUENTA A NINGÚN OTRO “USUARIO”. EN NINGÚN MOMENTO EL “USUARIO” TENDRÁ EL DERECHO DE HACER USO DE LOS "SERVICIOS Y CONTENIDOS" DE LA “PÁGINA” SEA INDIRECTA O DIRECTAMENTE EN SITIOS O PÁGINAS
              DE TERCEROS O PROPIAS SIN PREVIA, EXPRESA Y ESCRITA AUTORIZACIÓN DEL REPRESENTANTE LEGAL DE “VIVUS”.
            </p>
            <p>
              QUEDA PROHIBIDO AL “USUARIO” EL INTERFERIR CON LOS CONTENIDOS, OPERACIONES Y/O BASES DE DATOS. AL NO RESPETAR LAS PRESENTES LIMITACIONES CAERÁ DIRECTAMENTE LA RESPONSABILIDAD EN EL “USUARIO” DE ACUERDO CON LAS LEYES APLICABLES Y SE LE OBLIGARÁ A REPARAR LOS DAÑOS Y PERJUICIOS CAUSADOS
              POR SUS ACCIONES.
            </p>

            <h2 className="text-xl font-bold">f) USOS PERMITIDOS</h2>
            <p>
              EL “USUARIO” SE HACE RESPONSABLE DEL APROVECHAMIENTO Y USO QUE DE A LOS SERVICIOS Y CONTENIDOS DE LA "PÁGINA", QUIEN DEBE UTIIZARLOS SOLAMENTE DE ACUERDO A LAS FUNCIONES PERMITIDAS DE LA “PÁGINA” Y A LOS USOS Y/O FACULTADES AUTORIZADAS EN LOS TÉRMINOS Y CONDICIONES, POR LO QUE EL
              “USUARIO” ES RESPONSABLE DE USARLOS CORRECTAMENTE Y DE MANERA QUE NO ATENTE CONTRA LAS NORMAS DE CONVIVENCIA Y USO DE INTERNET, LAS LEYES DE MÉXICO Y LA LEGISLACÓN VIGENTE DEL PAÍS EN QUE EL “USUARIO” ESTE UBICADO AL USARLOS.{" "}
            </p>

            <h2 className="text-xl font-bold">g) AUTENTICACIÓN ELECTRÓNICA</h2>
            <p>
              “VIVUS” LLEVA A CABO LA “UTILIZACIÓN DE MEDIOS ELECTRÓNICOS DE AUTENTICACIÓN” A TRAVÉS DE UN NIP PARA ASÍ PODER REALIZAR CONSULTAS A LA SOCIEDAD DE INFORMACIÓN CREDITICIA POR MEDIO DE LA APLICACIÓN Y/O PÁGINA, ESTA SIN REQUERIR LA FIRMA AUTÓGRAFA DEL “USUARIO” SIEMPRE Y CUANDO SE
              ACEPTAN LOS PRESENTES TÉRMINOS Y CONDICIONES, EN VIRTUD DE LOS CUALES SE CREA UNA RELACIÓN JURIDICA ENTRE LAS PARTES.{" "}
            </p>

            <h2 className="text-xl font-bold">h) CALIDAD DE SERVICIOS Y CONTENIDOS</h2>
            <p>
              “VIVUS”, SUS SOCIOS COMERCIALES O PROVEEDORES NO ASUMEN RESPONSABILIDAD ALGUNA RESPECTO DE LOS DAÑOS O PERJUICIOS QUE PUEDIERA SUFRIR EL “USUARIO” COMO CONSECUENCIA DE ERRORES TÉCNICOS, TIPOGRÁFICOS, CAMBIOS O MODIFICACIONES REALIZADAS A LA “PÁGINA” O A LOS SERVICIOS Y CONTENIDOS, O
              INEXACTITUDES. EN TAL CASO, “VIVUS” RECOMIENDA QUE EL “USUARIO” PIDA LA AYUDA DE UN PROFESIONAL QUE PUEDA BRINDARLE ASESORÍA ESPECIALIZADA QUE CUBRA SUS NECESIDADES.{" "}
            </p>

            <h2 className="text-xl font-bold">i) MODIFICACIONES</h2>
            <p>“VIVUS” SE RESERVA EL DERECHO DE REALIZAR CAMBIOS O MODIFICACIONES EN CUALQUIER MOMENTO A SUS TÉRMINOS Y CONDICIONES DE USO Y PRIVACIDAD DE LA "PÁGINA". POR LO ANTERIOR, ES RESPONSABILIDAD DEL “USUARIO” LEERLOS CADA VEZ QUE VAYA A HACER USO DE LA “PÁGINA” Y SUS SERVICIOS.</p>

            <h2 className="text-xl font-bold">j) COOKIES</h2>
            <p>EL “USUARIO” QUE ACCESE A LA “PÁGINA” ACEPTA EXPRESAMENTE RECIBIR LAS COOKIES QUE LE SEAN TRANSMITIDAS POR LOS SERVICIOS DE “VIVUS”.</p>
            <p>LOS PRESENTES TÉRMINOS Y CONDICIONES CORRESPONDEN ÚNICAMENTE A LOS SERVICIOS Y LA “PÁGINA” DE “VIVUS”, POR LO QUE “VIVUS” NO ES RESPONSABLE DE LOS SITIOS WEB QUE SE MUESTREN COMO RESULTADO DE ENLACES O RESULTADOS DE BÚSQUEDAS EN NUESTRA “PÁGINA”.</p>
            <p>
              LAS COOKIES PUEDEN TENER INFORMACIÓN PARA RASTREAR LAS PÁGINAS QUE EL “USUARIO” HAYA VISITADO, INFORMACIÓN DEL “USUARIO” COMO LA IDENTIFICACIÓN PROPORCIONADA POR EL “USUARIO”. UNA COOKIE NO PUEDE ACCEDER A LA INFORMACIÓN O DATOS DEL DISCO DURO DEL “USUARIO” O VER LAS COOKIES CREADAS
              POR OTRAS PÁGINAS O SITIOS.
            </p>
            <h2 className="text-xl font-bold">k) VIOLACIONES DE BASE DE DATOS O DEL SISTEMA</h2>
            <p>
              SE PROHIBE CUALQUIER ACCIÓN, O USO DE CUALQUIER MEDIO QUE PUEDA INTERFERIR LA BASE DE DATOS U OPERACIONES Y ACTIVIDADES DE “VIVUS”, POR LO QUE CUALQUIER “USUARIO” QUE VIOLE, ENTTROMETA O REALICE ACTIVIDAD CONTRARIA A LAS PROHIBICIONES ESTIPULADAS EN ESTE CONTRATO O AL DERECHO DE
              PROPIEDAD INTELECTUAL, PADECERÁ LAS CONSECUENCIAS ESTIPULADAS EN ESTE CONTRATO Y SERÁ RESPONSABLE DE INDEMNIZAR A “VIVUS” POR LOS DAÑOS OCASIONADOS.{" "}
            </p>

            <h2 className="text-xl font-bold">l) INCUMPLIMIENTO POR PARTE DEL “USUARIO”</h2>
            <p>
              EL INCUMPLIMIENTO TANTO DE ESTOS TÉRMINOS Y CONDICIONES, COMO DE LAS DISPOSICIONES, REGLAS O INTERFACES RELACIONADAS, TENDRÁ COMO RESULTADO LA SUSPENSIÓN DE LA CUENTA DEL “USUARIO”, SIN PERJUICIO DE LAS SANCIONES O RESPONSABILIDADES DESCRITAS EN LA LEY Y LEGISLACIONES APLICABLES. EL
              INCUMPLIMIENTO E INOBSERVANCIA POR PARTE DEL “USUARIO” DE LAS OBLIGACIONES CREDITICAS SE INFORMARÁ OPORTUNAMENTE A LAS INSTITUCIONES DE INFORMACIÓN CREDITICIA.{" "}
            </p>

            <h2 className="text-xl font-bold">m) LEYES APLICABLES Y JURISDICCIÓN</h2>
            <p>
              ESTE ACUERDO ESTARÁ REGIDO EN TODOS SUS PUNTOS POR LAS LEYES VIGENTES EN LA REPUBLICA MEXICANA, EN PARTICULAR RESPECTO DE MENSAJES DE DATOS, CONTRATACIÓN ELECTRÓNICA Y COMERCIO ELECTRÓNICO SE REGIRÁ POR LO DISPUESTO POR LA LEGISLACIÓN FEDERAL RESPECTIVA. CUALQUIER CONTROVERSIA DERIVADA
              DEL PRESENTE ACUERDO, SU EXISTENCIA, VALIDEZ, INTERPRETACIÓN, ALCANCE O CUMPLIMIENTO, SERÁ SOMETIDA A LAS LEYES APLICABLES Y A LOS TRIBUNALES COMPETENTES.{" "}
            </p>
            <p>
              PARA LA INTERPRETACIÓN, CUMPLIMIENTO Y EJECUCIÓN DEL PRESENTE CONTRATO, LAS PARTES EXPRESAMENTE SE SOMETEN A LA JURISDICCIÓN DE LOS TRIBUNALES FEDERALES COMPETENTES DE LA CIUDAD DE MÉXICO RENUNCIANDO EN CONSECUENCIA A CUALQUIER FUERO QUE EN RAZÓN DE SU DOMICILIO PRESENTE O FUTURO
              PUDIERA CORRESPONDERLES.
            </p>

            <h2 className="text-xl font-bold">n) ADVERTENCIAS</h2>
            <p>INCUMPLIR TUS OBLIGACIONES TE PUEDE GENERAR COMISIONES E INTERESES MORATORIOS. CONTRATAR CRÉDITOS POR ARRIBA DE TU CAPACIDAD DE PAGO PUEDE AFECTAR TU HISTORIAL CREDITICIO.</p>

            <p className="w-full text-center text-gray-500 mt-20 pt-20">CALLE CERES 23, CRÉDITO CONSTRUCTOR, 03940, BENITO JUAREZ, CIUDAD DE MÉXICO.</p>
            <p className="w-full text-center text-gray-500 mt-20 pt-20">FECHA DE ACTUALIZACIÓN: MAYO, 2024.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TerminosCondiciones;
