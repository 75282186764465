import React from "react";

const CallToAction = () => (
  <div className="flex justify-center lg:px-24 2xl:px-64 bg-green-dark">
    <div className="flex container px-8 gap-4 flex-col items-center justify-center py-12 lg:py-24 w-full">
      <h2 className="text-white text-3xl lg:text-4xl text-center">Obtén tu primer préstamo con nosotros en minutos y recibe el dinero en tu cuenta bancaria.*</h2>
      <p className="text-xl text-white text-center">Conoce desde el inicio cuánto debes pagar.</p>
      <a className="bg-green-light hover:bg-white transition-all font-bold text-white hover:text-green-dark py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href="/registration">
        ¡SOLICÍTALO AQUÍ!
      </a>
    </div>
  </div>
);

export default CallToAction;
