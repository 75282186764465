import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "pageChange",
    page: {
      url: "/terminos-y-condiciones-promocion",
      referrer: sessionStorage.getItem("utm") || "/",
    },
  },
  dataLayerName: "dataLayer",
};
export const TerminosCondicionesPlazos = () => {
  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-white relative w-full flex justify-center flex-1 lg:py-24 py-4">
      <div className="container flex flex-col gap-4 p-4 max-w-4xl">
        <div className="flex flex-col gap-4 py-4">
          {/* <DemoButton callback={fillDemo} /> */}
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Términos y condiciones plazos</h1>
          </div>
          <p>Difinance, S.A. de C.V., SOFOM, E.N.R. (en lo sucesivo “VIVUS”), con domicilio en: Ceres 23, Crédito Constructor, Benito Juárez, Ciudad de México.</p>
          <p>Es obligación y responsabilidad del “Usuario” leer y aceptar los siguientes términos y condiciones antes de utilizar los servicios de “VIVUS” (en lo sucesivo el “Usuario”, “Cliente” y/o “Acreditado”.</p>
          <p>
            El plazo del crédito quedará establecido conforme a lo pactado entre el cliente y VIVUS, el cual puede variar entre 7 y 30 días. Sin embargo, el cliente podrá acceder a un plazo de hasta 90 días (plazo promocional). CAT: la tasa de interés mensual ordinaria aplicable es de 42.58% más el
            IVA. Por defecto, la tasa de interés anual fija es de 511.00% más el IVA más Comisión por apertura 4%; Comisión por Disposición del Crédito 1%; Comisión por Gastos de Investigación 2.5%, más IVA cada una. O un{" "}
            <b className="font-bold">
              <u> Costo Anual Total (CAT) PROMEDIO: 9150.33% sin IVA por $8,000 M.N. calculado sobre un préstamo de 60 días en dos pagos. Solamente para información y fines comparativos.</u>
            </b>
          </p>
          <p>
            El acceso de los clientes al plazo mencionado anteriormente estará condicionado al análisis del historial crediticio de los clientes. VIVUS notificará al cliente respecto del plazo máximo aprobado sobre su solicitud y habilitará en el Perfil del Acreditado la opción a ampliar su
            documentación. En caso de que el cliente sea elegible para un crédito con un plazo promocional establecido en los presentes términos y condiciones deberá adicionalmente previo a la celebración del contrato proporcionar a VIVUS los siguientes documentos a través del Perfil del Acreditado:
            i) Constancia de su Clave Única de Registro de Población, ii)Constancia de Situación Fiscal actualizada con una vigencia no menor a un mes iii) Comprobante de domicilio con una vigencia no menor a tres meses y iii) El número de serie de su Firma Electrónica Avanzada en caso de contar con
            esta.
          </p>
          <p>
            Los créditos personales podrán ser adquiridos en los Estados Unidos Mexicanos. No existen renovaciones automáticas de créditos. Cualquier préstamo o monto adicional otorgado por VIVUS deberá ser solicitado por el cliente, quien deberá aceptar explícitamente las condiciones de montos y
            plazos previamente.
          </p>
          <p>El término Comisiones se refiere a Comisión por apertura 4%; Comisión por Disposición del Crédito 1%; Comisión por Gastos de Investigación 2.5%, más IVA cada una. Todas las comisiones son calculadas sobre el monto del crédito.</p>

          <p>Vigencia 01 de enero del 2024 al 31 de diciembre de 2024.</p>
          <p>
            El cliente al realizar su solicitud de crédito a VIVUS otorga su consentimiento, de conformidad con el artículo 28 de la Ley para Regular las Sociedades de Información Crediticia, pueda consultar su historial crediticio de forma periódica ante dichas entidades; lo anterior, para efecto
            de que VIVUS pueda llevar un adecuado análisis para el otorgamiento de Crédito.
          </p>

          <p className="font-bold text-lg">DIFINANCE S.A. DE C.V., SOFOM E.N.R.</p>
          <p className="font-bold text-lg">Fecha:</p>

          <div className="w-full justify-center flex flex-col gap-4">
            <p className="font-bold text-center">TABLA DE AMORTIZACIÓN</p>

            <div class="overflow-x-auto">
              <table class="min-w-full bg-white">
                <thead class="border">
                  <tr>
                    <th scope="col" class="text-gray-900 px-6 py-4 text-left border" colspan="4">
                      Nombre del cliente:
                    </th>
                    <th scope="col" class="text-gray-900 px-6 py-4 text-left border" colspan="4">
                      Número de crédito:
                    </th>
                    {/* <!-- Agrega más cabeceras de columnas según sea necesario --> */}
                  </tr>
                </thead>
                <tbody>
                  <tr class="border">
                    <th class="px-6 py-4 whitespace-nowrap text-gray-900" colspan="1"></th>
                    <th class="text-gray-900 px-6 py-4 text-left" colspan="7">
                      Fecha o número de periodo:
                    </th>
                  </tr>
                  <tr class="border">
                    <th class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      Fecha límite de pago/corte
                    </th>
                    <th class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      Saldo insoluto de Capital
                    </th>
                    <th class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      Capital
                    </th>
                    <th class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      Intereses ordinarios
                    </th>
                    <th class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      Pago periódico
                    </th>
                    <th class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      IVA de intereses
                    </th>
                    <th class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      Comisiones
                    </th>
                    <th class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      Total a pagar
                    </th>
                  </tr>
                  <tr class="border">
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      --/--/----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1"></td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                  </tr>
                  <tr class="border">
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      --/--/----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1"></td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                  </tr>
                  {/* <!-- Repite filas según sea necesario --> */}
                </tbody>
                <tfoot>
                  <tr>
                    <td class="text-sm font-medium text-gray-900 px-6 py-4 whitespace-nowrap" colspan="4">
                      {/* <!-- Monto total a pagar --> */}
                    </td>

                    <td colspan="2" class="text-gray-900 px-6 py-4 text-left font-bold border">
                      MONTO TOTAL A PAGAR
                    </td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1"></td>
                    <td class="px-6 py-4 text-gray-900 max-w-[100px] border" colspan="1">
                      $----
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
