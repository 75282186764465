import React from "react";
import { useWordpress } from "../../../hooks/wordpress";
import EllipsisText from "react-ellipsis-text";

const BLOG_URL = "https://www.vivus.com.mx/blog/";

const HomeBlog = () => {
  const { featuredPosts } = useWordpress();

  const stripHtml = (html) => {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  return (
    <div className="flex justify-center lg:px-24 2xl:px-64 bg-gray-200">
      <div className="flex container px-8 gap-4 flex-col items-center justify-center py-12 lg:py-24 w-full">
        <h2 className="text-gray-800 lg:text-4xl text-3xl font-bold text-center">Blog: consejos financieros y tips para manejar tu préstamo personal</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {featuredPosts.map((post, key) => (
            <a key={post.id} href={BLOG_URL + post.slug} target="_blank" rel="noopener">
              <div className="flex flex-col gap-2 rounded-xl bg-white">
                <img src={post._embedded["wp:featuredmedia"][0]?.source_url} alt={post.title.rendered} className="rounded-t-xl h-[270px] object-cover" />
                <div className="flex flex-col gap-4 p-4">
                  <h3 className="text-xl font-bold text-green-dark h-12">{post.title.rendered}</h3>
                  <EllipsisText className="text-gray-800 py-2" text={stripHtml(post.excerpt.rendered)} length={"150"}></EllipsisText>
                </div>
              </div>
            </a>
          ))}
        </div>
        <a className="bg-green-light hover:bg-white transition-all font-bold text-white hover:text-green-dark py-4 px-8 my-2 rounded-lg lg:max-w-sm flex items-center justify-center text-center self-center justify-self-center text-xl" href={BLOG_URL}>
          Visitar blog
        </a>
      </div>
    </div>
  );
};
export default HomeBlog;
