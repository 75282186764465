import React, { useState, useEffect } from "react";
import cookie from "react-cookies";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import { useLocation } from "../../../../hooks/location";
import { getToken, changeDebit, getBanks } from "../../../../services/api";
import Button from "../../../common/Button";

const idProduct = 1;

const PaymentDebit = ({ history, setSection }) => {
  const [token, setToken] = useState(null);
  const [card, setCard] = useState("");
  const [banks, setBanks] = useState([]);
  const [bank, setBank] = useState("");
  const [bankError, setBankError] = useState("");
  const [cardErr, setCardErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentChange, setCurrentChange] = useState(false);
  const { location, ip } = useLocation();

  const checkClabe = async () => {
    let response = await getToken();
    if (!response) return setCardErr(true);
    let validToken = response.data.token;
    if (!card) return setCardErr(true);
    let regEx = /^([0-9]{16})$/;
    const user = JSON.parse(sessionStorage.getItem("loggedUser"));
    const data = {
      debitCard: card,
      idBank: parseInt(bank),
      customerIP: ip,
      coordinates: location,
      userAgent: window.navigator.userAgent,
      idCustomer: user.customerId,
      idProduct: idProduct,
    };
    if (regEx.test(card)) {
      setCardErr(false);
      if (bank === "") return setBankError(true);
      setBankError(false);
      changeDebit(data, validToken)
        .then((res) => {
          setSuccess(true);
        })
        .catch((err) => {
          if (err.response.status === 403) return setCurrentChange(true);
          setCardErr(true);
        });
    } else {
      setCardErr(true);
    }
  };

  const loadBanks = async () => {
    let response = await getToken();
    if (!response) return setBanks(["Server error"]);
    let validToken = response.data.token;
    getBanks(validToken)
      .then((res) => {
        setBanks(res.data.catalogs[0].records);
      })
      .catch((err) => setBanks(["Server error"]));
  };

  useEffect(() => {
    let getToken = cookie.load("token");
    if (!getToken) return history.push("/login");
    setToken(getToken);
    loadBanks();
  }, []);

  return (
    <div>
      {success ? (
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl">Listo, el cambio de tu tarjeta de débito está en trámite</h3>
          <p className="text-gray-500">Realizaremos una validación y en breve te informaremos el resultado, espéralo en tu correo electrónico en un plazo de 24 horas hábiles</p>
          <p className="text-red-500">
            Al continuar, acepto que este nuevo número es válido para actualizar mi formato de autorización de cargo automático por el monto de las parcialidades que en su caso tenga pendientes. Tarjeta de débito número: <br />
            {card}
          </p>
          <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
            <p>Regresar</p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl">Tarjeta de débito</h3>

          <div className={`flex flex-col gap-2 lg:w-96`}>
            <label className="font-bold">Número de tarjeta de débito</label>
            <input className="h-10 w-full bg-white rounded-xl px-4 text-gray-800 border border-gray-200 shadow-sm" maxLength="16" onChange={(e) => setCard(e.target.value)} value={card} type="text" />
            {currentChange ? <span className="text-red-500">Ya tienes un trámite en proceso.</span> : null}
            {cardErr ? <span className="text-red-500">Numero de tarjeta incorrecto.</span> : null}
          </div>
          <div className={`flex flex-col gap-2 lg:w-96`}>
            <label className="font-bold">Emisor de tarjeta de débito</label>
            <select onChange={(e) => setBank(e.target.value)} value={bank} type="text" className="h-10 w-full bg-white rounded-xl px-4 text-gray-800 border border-gray-200 shadow-sm">
              <option value="" disabled selected>
                {banks.length > 0 ? "Selecciona un banco" : "Cargando bancos"}
              </option>
              {banks.map((bank) => (
                <option key={bank.value} value={bank.value}>
                  {bank.text}
                </option>
              ))}
            </select>
            {bankError ? <span className="text-red-500">Selecciona un banco.</span> : null}
          </div>

          <Button className={`lg:w-96 text-center`} onClick={checkClabe}>
            GUARDAR
          </Button>
          <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
            <p>Regresar</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(PaymentDebit);
