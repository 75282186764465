import React, { useState, useEffect } from "react";
import Countdown from "react-countdown-now";
import { withRouter, Link } from "react-router-dom";
import { BallBeat } from "react-pure-loaders";
import cookie from "react-cookies";
import { getToken, checkCode, recoverPassword, getCustomerByMail } from "../../services/api";
import Button from "../common/Button";
import { useLocation } from "../../hooks/location";

const idProduct = 1;

const PasswordRecoveryEmailConfirmation = (props) => {
  const [eMail, setEMail] = useState(null);
  const [counter, setCounter] = useState(false);
  const [codigo, setCodigo] = useState(null);
  const [codeError, setCodeError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { location, ip } = useLocation();

  useEffect(() => {
    let getEmail = sessionStorage.getItem("recoveryEmail");
    if (!getEmail) return props.history.push("/login");
    setEMail(getEmail);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("loggedUser") && cookie.load("token")) props.history.push("/dashboard/initial");
  }, []);

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setCounter(true);
      return null;
    } else {
      // Render a countdown
      return <span className="text-gray-500">INTENTA DE NUEVO ({seconds} SEC.)</span>;
    }
  };

  const handleCode = async () => {
    if (!codigo) return setCodeError(true);
    if (codigo.length > 4) return setCodeError(true);
    setCodeError(false);
    setLoading(true);
    let data = {
      code: parseInt(codigo),
      idProduct,
      isNIP: false,
      userAgent: navigator.userAgent,
      clientIP: ip,
      coordinates: location,
    };
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    getCustomerByMail(idProduct, eMail, validToken).then((res) => {
      if (res.status === 200) {
        data.idCustomer = res.data.customerId;
        localStorage.recoverIdCustomer = res.data.customerId;
        checkCode(data, validToken)
          .then((res) => {
            if (res.data.idStatus === 1) {
              sessionStorage.setItem("code-valid", "true");
              return props.history.push("/nuevo-password-confirmar");
            }
            setCodeError(true);
            setLoading(false);
          })
          .catch((err) => {
            setCodeError(true);
            setLoading(false);
          });
      }
    });
  };

  const sendAgain = async () => {
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    const data = {
      idProduct,
      eMail,
    };
    recoverPassword(data, validToken);
  };

  return (
    <div className="bg-white relative w-full flex justify-center items-center flex-1">
      <div className="container flex flex-col gap-4 p-4 ">
        <div className="flex flex-col gap-4 py-4 max-w-xl">
          {/* <DemoButton callback={fillDemo} /> */}
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Correo enviado</h1>
            <h2 className="text-xl lg:text-2xl text-green-light">
              Hemos enviado un link para que puedas recuperar tu acceso a tu email <strong className="font-bold">{eMail}</strong>.
            </h2>
          </div>
          <div className={`flex flex-col gap-2`}>
            <label className="font-bold">Código para recuperación </label>
            <input className="h-10 w-full bg-white rounded-xl px-4 text-gray-800 border border-gray-200 shadow-sm lg:w-56" type="text" name="codigo" maxLength="4" placeholder="Código para recuperación" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
            {codeError ? <span className="text-red-500 ">Código incorrecto</span> : null}
          </div>
          <Button className="lg:w-56" onClick={handleCode}>
            {loading ? <BallBeat color={"#fff"} loading /> : "CONFIRMAR"}
          </Button>
          {counter ? (
            <p
              className="text-green-light underline hover:text-green-dark transition-all"
              onClick={() => {
                sendAgain();
                setCounter(false);
              }}
            >
              INTENTA DE NUEVO
            </p>
          ) : (
            <Countdown date={Date.now() + 60000} renderer={renderer} />
          )}
          <p>(Antes de reenviarte revisa tu carpeta de SPAM)</p>
          <Link className="text-green-light underline hover:text-green-dark transition-all" to="/recupera-opcion">
            Cambia opción de recuperación
          </Link>
          <Link className="text-green-light underline hover:text-green-dark transition-all" to="/login">
            Cancelar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PasswordRecoveryEmailConfirmation);
