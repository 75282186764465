import React from 'react'
import { Helmet } from "react-helmet";

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Página no encontrada | Vivus Mx </title>
        <meta name="title" content="Préstamo rechazado | Vivus Mx" />
        <meta name="description" content="¡Consigue dinero rápido con nuestros préstamos en línea! Solicita ahora. ¿Necesitas dinero en efectivo? Solicita nuestro préstamo digital en minutos." />
        <link rel="alternate" href={window.location.protocol + "//" + window.location.host + window.location.pathname} hreflang="es-mx" />
        <link rel="canonical" href={window.location.protocol + "//" + window.location.host + window.location.pathname} />
      </Helmet>
      <div className="bg-white relative w-full flex justify-center flex-1 min-h-[900px]">
        <div className="container flex flex-col gap-8 px-4 py-12 self-center">
          <div className="w-full flex flex-col gap-2 items-center">
            <span className="text-[200px] text-green-light">404</span>
            <h1 className="text-3xl lg:text-6xl font-bold text-green-dark text-center">Lo sentimos</h1>
            <h2 className="text-2xl lg:text-4xl text-center">La página que buscas no se encuentra disponible.</h2>
            <p className="text-xl text-center">¿Quizás estés buscando algo específico? Explora nuestras categorías destacadas</p>
            <div className="flex gap-8 flex-col lg:flex-row justify-between">
              <a href="/como-funciona" className="text-green-dark font-bold hover:text-green-light transition-all text-xl underline text-center">
                ¿Cómo funciona?
              </a>
              <a href="/sobre-nosotros" className="text-green-dark font-bold hover:text-green-light transition-all text-xl underline text-center">
                Sobre nosotros
              </a>
              <a href="https://www.vivus.com.mx/blog" className="text-green-dark font-bold hover:text-green-light transition-all text-xl underline text-center">
                Blog
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};