import React from "react";
import PropTypes from "prop-types";
import Button from "../common/Button";

const ContractModal = ({ contract, onClose = () => null, isPDF = false }) => {
  return (
    <div className="bg-black bg-opacity-50 fixed h-screen w-screen flex top-0 left-0 items-center justify-center p-4 md:px-20" onClick={onClose}>
      <div
        className="bg-white h-5/6 w-full overflow-auto flex flex-col"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {isPDF && <iframe className="overflow-auto flex-1 p-4 px-12" src={`data:application/pdf;base64,${contract}`} />}
        {!isPDF && <div className="overflow-auto flex-1 p-4 px-12" dangerouslySetInnerHTML={contract} />}
        <div className="flex w-full items-center justify-center py-4">
          <Button onClick={onClose}>Cerrar</Button>
        </div>
      </div>
    </div>
  );
};

ContractModal.propTypes = {};
ContractModal.defaultValues = {};

export default ContractModal;
