import { useEffect, useState } from "react";

export const useWordpress = () => {
  const [featuredPosts, setFeaturedPosts] = useState([]);

  const getFeaturedPosts = async () => {
    const cacheController = new Date().getTime();
    const response = await fetch("https://blog.vivus.com.mx/wp-json/wp/v2/posts?per_page=3&order=desc&orderby=date&_embed&date=" + cacheController, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const posts = await response.json();
    console.log(posts);
    return posts;
  };

  useEffect(() => {
    getFeaturedPosts().then((posts) => setFeaturedPosts(posts));
  }, []);

  return { featuredPosts };
};
