import { useEffect, useState } from "react";

export const useLoginPopUp = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [isRepeater, setIsRepeater] = useState(false);
  const [popupShown, setPopupShown] = useState(false);

  // Check localStorage to see if popup has showed before.
  useEffect(() => {
    if (isRepeater && !popupShown) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [isRepeater, popupShown]);

  //Persist isRepeater value on sessionStorage
  useEffect(() => {
    sessionStorage.setItem("popup_is_repeater", isRepeater);
  }, [isRepeater]);

  //Persist popupShown value on sessionStorage
  useEffect(() => {
    sessionStorage.setItem("popup_shown", popupShown);
  }, [popupShown]);

  //Get isRepeater value from sessionStorage at start
  useEffect(() => {
    const isRepeater = sessionStorage.getItem("popup_is_repeater") === "true";
    const popupShown = sessionStorage.getItem("popup_shown") === "true";
    setIsRepeater(isRepeater);
    setPopupShown(popupShown);
  });

  return {
    showPopup,
    setPopupShown,
    setIsRepeater,
    isRepeater,
  };
};
