import React, { useState, useEffect } from "react";
import "./newStyles.scss";
import { withRouter, Link } from "react-router-dom";
import {
  getFilledContract,
  getToken,
  getCustomerByMail,
  getStatus,
  getProposal,
  getSimulation,
  changeCustomerMobile,
  setContractAuthorization,
  getAnalytics,
  setDirectDebitAuthorization,
  sendCodeBySMS,
  checkCode,
  sendCodeByWA,
  sendCodeByNewSMS,
  getDocumentList,
  sendCodeByNewWA,
} from "../../services/api";
import { momentEs } from "../../services/moment";
import publicIp from "public-ip";
import TagManager from "react-gtm-module";
import { BallClipRotate } from "react-pure-loaders";
import { BallBeat } from "react-pure-loaders";
import CheckBox from "../common/checkbox";
import Button from "../common/Button";
import { useLocation } from "../../hooks/location";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useAnalytics } from "../../hooks/use-analytics";
import moment from "moment";
import ContractModal from "../register/ContractModal";

const idProduct = 1;

const Cargo = (props) => {
  const [customer, setCustomer] = useState(null);

  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [directDebitError] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [contract, setContract] = useState(null);
  const [isReturning, setIsReturning] = useState(false);
  const [authorizationAddressing, setAuthorizationAddressing] = useState(true);
  const [phoneChanged, setPhoneChanged] = useState(false);
  const [continueDisabled, setContinueDisabled] = useState(false);

  //Verification variables
  const [nip, setNip] = useState(null);
  const [nipError, setNipError] = useState(null);
  const [sentBySMS, setSentBySMS] = useState(true);
  const [timeToSend, setTimeToSend] = useState(89);
  const [authorized, setAuthorized] = useState(true);
  const [changeNumberMode, setChangeNumberMode] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneServerError, setPhoneServerError] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [insurance, setInsurance] = useState(false);
  const [balance, setBalance] = useState({
    amount: 0,
    creditLimitUsed: 0,
    installments: [
      { idDeferral: "cargando...", dueDate: new Date(), interest: 0 },
      { idDeferral: "cargando...", dueDate: new Date(), interest: 0 },
      { idDeferral: "cargando...", dueDate: new Date(), interest: 0 },
      { idDeferral: "cargando...", dueDate: new Date(), interest: 0 },
      { idDeferral: "cargando...", dueDate: new Date(), interest: 0 },
      { idDeferral: "cargando...", dueDate: new Date(), interest: 0 },
    ],
    term: 0,
    commision: 0,
    idFrequency: 3,
    currentInstallment: {
      paymentValue: 0,
    },
  });

  const [open, setOpen] = useState(false);
  const [askPhoneRepeater, setAskPhoneRepeater] = useState(false);
  const [nipEnteredRepeater, setNipEnteredRepeater] = useState(false);
  const [loadedRepeater, setLoadedRepeater] = useState(false);
  const [showNIP, setShowNIP] = useState(false);
  const { location, ip } = useLocation();
  const { trackPageView } = useMatomo();
  const { dataLayerPushEvent } = useAnalytics();
  const [showPromo, setShowPromo] = useState(false);

  const goTo = async () => {
    if (customer.eMail === "demo@demo.com") return props.history.push("/dashboard/id");
    setLoadingConfirm(true);
    setContinueDisabled(true);
    const onCoords = async ({ coords, timestamp }) => {
      if (ip) {
        const data = {
          idProduct,
          idCustomer: customer.customerId,
          authorized: termsAccepted,
          userAgent: navigator.userAgent,
          ip,
          coordinates: location,
          requestInsurance: true,
        };
        let response = await getToken();
        const validToken = response.data.token;

        setContractAuthorization(data, validToken)
          .then((res) => {
            sessionStorage.removeItem("showNIPOnPreApproved");
            if (res.status === 200) {
              setLoadingConfirm(false);
              getDocumentList(
                {
                  idProduct,
                  idCustomer: customer.customerId,
                  idAction: 2,
                  customerChecked: insurance,
                },
                validToken
              ).then(() => {
                if (isReturning) {
                  //Se llama setDirectDebitAuthorization y se redirige a la pantalla correcta.
                  setDirectDebitAuthorization(data, validToken)
                    .then((res) => {
                      getStatus(idProduct, customer.customerId, false, validToken).then((response) => {
                        if (response.status === 200) {
                          trackPageView({
                            documentTitle: "Final Approval",
                            href: "https://dfi.world",
                          });
                          dataLayerPushEvent("Credit_approved", customer.customerId);

                          if (response.data.idSubStatus === 15) return props.history.push("/application-complete");
                          if (response.data.idSubStatus === 217) return props.history.push("/identity-validated");
                          if (response.data.idSubStatus === 218) return props.history.push("/application-complete");
                          if (response.data.idSubStatus === 219) return props.history.push("/identity-validated");
                          if (response.data.idSubStatus === 206) return props.history.push("/application-review");
                          if (response.data.idStatus === 7) return props.history.push("/dashboard/general");
                        }
                        trackPageView({
                          documentTitle: "Form Rejected",
                          href: "https://dfi.world",
                        });
                        return props.history.push("/denied");
                      });
                    })
                    .catch((err) => console.log(err));
                } else {
                  //Se pasa directamente a la pantalla de cargar identificación.
                  trackPageView({
                    documentTitle: "Load Pictures",
                    href: "https://dfi.world",
                  });
                  if (res.data.codigo === "200") return props.history.push("/dashboard/id");
                }
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              setLoadingConfirm(false);
              setServerError(true);
            }
          });
      }
    };
    const onErr = async (err) => {
      const myIp = await publicIp.v4();
      if (myIp) {
        sessionStorage.setItem("ip", myIp);
        sessionStorage.setItem("coords", err);
        const data = {
          idProduct,
          idCustomer: customer.customerId,
          authorized: termsAccepted,
          userAgent: navigator.userAgent,
          ip: myIp,
          coordinates: err.message,
          requestInsurance: true,
        };
        let response = await getToken();
        const validToken = response.data.token;
        setContractAuthorization(data, validToken)
          .then((res) => {
            if (res.status === 200) {
              setLoadingConfirm(false);
              getDocumentList(
                {
                  idProduct,
                  idCustomer: customer.customerId,
                  idAction: 2,
                  customerChecked: insurance,
                },
                validToken
              ).then(() => {
                if (isReturning) {
                  //Se llama setDirectDebitAuthorization y se redirige a la pantalla correcta.
                  setDirectDebitAuthorization(data, validToken)
                    .then((res) => {
                      getStatus(idProduct, customer.customerId, false, validToken).then((response) => {
                        if (response.status === 200) {
                          if (response.data.idSubStatus === 15) return props.history.push("/application-complete");
                          if (response.data.idSubStatus === 217) return props.history.push("/identity-validated");
                          if (response.data.idSubStatus === 218) return props.history.push("/application-complete");
                          if (response.data.idSubStatus === 219) return props.history.push("/identity-validated");
                          if (response.data.idSubStatus === 206) return props.history.push("/application-review");
                          if (response.data.idStatus === 7) return props.history.push("/dashboard/general");
                        }
                        return props.history.push("/denied");
                      });
                    })
                    .catch((err) => console.log(err));
                } else {
                  //Se pasa directamente a la pantalla de cargar identificación.
                  if (res.data.codigo === "200") return props.history.push("/dashboard/id");
                }
              });
            }
            setLoadingConfirm(false);
            setServerError(true);
          })
          .catch((err) => {
            setLoadingConfirm(false);
            setServerError(true);
          });
      }
    };
    navigator.geolocation.getCurrentPosition(onCoords, onErr);
  };

  const sendSMS = async () => {
    const loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    const data = {
      idProduct,
      idCustomer: loggedUser.customerId,
      isNIP: false,
    };
    // let smsNewData = {
    //   newMobile: loggedUser.mobile,
    //   userAgent: navigator.userAgent,
    //   remoteIp: "",
    //   coordinates: "",
    //   isNIP: false,
    //   idCustomer: loggedUser.idCustomer,
    //   idProduct,
    // };

    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;

    // const askPhoneRepeater = sessionStorage.getItem("ask_phone_repeater");

    return sendCodeBySMS(data, validToken)
      .then(() => setSentBySMS(true))
      .catch((err) => console.log(err));
    // return w(data, validToken)
    //   .catch((err) => console.log(err))
    //   .finally(() => {
    //     return sendCodeBySMS(data, validToken).then(() => setSentBySMS(true));
    //   });
  };

  const sendNewSMS = async (phone) => {
    const { customerId } = JSON.parse(sessionStorage.getItem("loggedUser"));

    const data = {
      newMobile: phone,
      userAgent: navigator.userAgent,
      remoteIp: ip,
      coordinates: location,
      isNIP: false,
      idCustomer: customerId,
      idProduct,
    };

    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;

    if (process.env.REACT_APP_SITE === "mivivus") {
      return sendCodeByNewWA(data, validToken)
        .then(() => setSentBySMS(true))
        .catch((err) => console.log(err));
    } else {
      return sendCodeByNewSMS(data, validToken)
        .then(() => setSentBySMS(true))
        .catch((err) => console.log(err));
    }
  };

  const fillContract = async (idCustomer, token) => {
    let contRes = await getFilledContract({ idProduct, idCustomer, hasInsurance: true }, token);
    if (contRes.data) {
      setContract(contRes.data.document);
    }
    return setLoading(false);
  };

  let checkPhoneError = (phone) => {
    let phoneArray = phone.split("");
    return phoneArray.every((v) => v === phoneArray[0]);
  };

  const handleChangeNumber = async () => {
    if (!phone) return setPhoneError(true);
    else setPhoneError(false);
    if (phone.length < 10) return setPhoneError(true);
    else setPhoneError(false);
    if (phone.match(/^[0-9]+$/) === null) return setPhoneError(true);
    else setPhoneError(false);
    if (phone.search(/[a-zA-Z]/) !== -1) return setPhoneError(true);
    else setPhoneError(false);
    if (checkPhoneError(phone)) return setPhoneError(true);
    else setPhoneError(false);

    try {
      let loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
      loggedUser.mobile = phone;
      sessionStorage.setItem("loggedUser", JSON.stringify(loggedUser));

      // const response = await changeCustomerMobile(
      //   {
      //     newEMail: loggedUser.eMail,
      //     newMobile: phone,
      //     userAgent: navigator.userAgent,
      //     remoteIP: ip,
      //     coordinates: location,
      //     isNIP: false,
      //     idCustomer: loggedUser.customerId,
      //     idProduct,
      //   },
      //   validToken
      // );
      await sendNewSMS(phone);
      setPhoneChanged(true);
      setChangeNumberMode(false);
      setTimeToSend(89);
      localStorage.setItem("timeToSendNIP", 89);
    } catch (e) {
      setPhoneServerError(true);
    }
  };

  const handleNIP = async () => {
    if (!authorized) return;
    if (!nip) return setNipError(true);
    if (nip.length > 4) return setNipError(true);
    setLoading(true);
    if (customer.eMail === "demo@demo.com") return props.history.push("/registration-complete");
    let response = await getToken();
    let validToken = response.data.token;

    let data = {
      idCustomer: customer.idCustomer || customer.customerId,
      code: parseInt(nip),
      idProduct,
      isNIP: false,
      userAgent: navigator.userAgent,
      clientIP: ip,
      coordinates: location,
    };

    // Validation to send the user to the correct screen
    return checkCode(data, validToken).then((res) => {
      if (res.status === 200) {
        if (phoneChanged) {
          const loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));

          changeCustomerMobile(
            {
              newEMail: loggedUser.eMail,
              newMobile: phone,
              userAgent: navigator.userAgent,
              remoteIP: ip,
              coordinates: location,
              isNIP: false,
              idCustomer: loggedUser.customerId,
              idProduct,
            },
            validToken
          ).then(() => {
            sessionStorage.removeItem("ask_phone_repeater");
            sessionStorage.setItem("nip_entered_repeater", true);
            setNipError(false);
            //setLoading(false);
            goTo();
          });
        } else {
          sessionStorage.removeItem("ask_phone_repeater");
          sessionStorage.setItem("nip_entered_repeater", true);
          setNipError(false);
          //setLoading(false);
          goTo();
        }
      } else {
        setNipError(true);
        setLoading(false);
      }
    });
  };

  const getBalance = (idCustomer, token) => {
    getProposal(idProduct, idCustomer, token)
      .then((res) => {
        if (res.status === 200) {
          getSimulation(idProduct, res.data.amount, res.data.opFrequency, res.data.term, idCustomer, token)
            .then((resSim) => {
              setBalance({
                ...balance,
                amount: res.data.amount,
                creditLimitUsed: res.data.creditLimit,
                term: res.data.term,
                idFrequency: res.data.opFrequency,
                startDate: resSim.data.startDate,
                installments: resSim.data.amortizationTable,
                commision: resSim.data.commision,
                currentInstallment: {
                  paymentValue: resSim.data.firstPaymentAmount,
                },
              });
              //TAG MANAGER
              getAnalytics({ idCustomer, idProduct }, token).then((analytics) => {
                if (analytics.data) {
                  const tagManagerArgs = {
                    dataLayer: {
                      event: "pageChange",
                      page: {
                        url: "/pre-approved",
                        referrer: "/",
                      },
                      client: {
                        hFN: analytics.data.hFn,
                        hLN: analytics.data.hLN,
                        hTN: analytics.data.hTN,
                        hMA: analytics.data.hMA,
                        dateOfBirth: analytics.data.dateOfBirth,
                        returningClient: analytics.data.returningClient,
                        identifiedBy: analytics.data.identifiedBy,
                        registeredBy: analytics.data.registeredBy,
                      },
                      loans: {
                        loansCount: analytics.data.loansCount,
                      },
                      lastest_loan: {
                        status: analytics.data.status,
                        id: analytics.data.id,
                        repaymentDate: analytics.data.repaymentDate,
                      },
                      application: {
                        id: analytics.data.application.id,
                      },
                    },
                    dataLayerName: "dataLayer",
                  };
                  TagManager.dataLayer(tagManagerArgs);
                  setIsReturning(analytics.data.returningClient === "RETURNING");
                  setIsReturning(analytics.data.returningClient === "RETURNING" || analytics.data.returningClient === "RETURNINGSLEEPER");
                  if (analytics.data.returningClient === "RETURNING" || analytics.data.returningClient === "RETURNINGSLEEPER") {
                    setAskPhoneRepeater(true);
                    sessionStorage.setItem("ask_phone_repeater", true);
                  }
                  setLoadedRepeater(true);
                }
              });
              //TAG MANAGER
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => {});
  };

  const loadLocalData = async () => {
    let localRegister = await JSON.parse(sessionStorage.getItem("data-step-registration"));
    if (!localRegister) return props.history.push("/login");
    let response = await getToken();
    const validToken = response.data.token;
    getCustomerByMail(idProduct, localRegister.eMail, validToken)
      .then((res) => {
        setCustomer(res.data);
        fillContract(res.data.customerId, validToken);
        getBalance(res.data.customerId, validToken);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const initialConfig = async () => {
      let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
      const loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
      if (demoUser || (loggedUser && loggedUser.eMail === "demo@demo.com")) {
        let dummyData = {
          amount: 2500,
          creditLimitUsed: 2500,
          term: 3,
          idFrequency: 1,
          startDate: new Date(),
          installments: [
            {
              idDeferral: 1,
              dueDate: new Date(),
              paymentValue: 200,
              interest: 25,
            },
            {
              idDeferral: 2,
              dueDate: new Date(),
              paymentValue: 200,
              interest: 25,
            },
            {
              idDeferral: 3,
              dueDate: new Date(),
              paymentValue: 200,
              interest: 25,
            },
            {
              idDeferral: 4,
              dueDate: new Date(),
              paymentValue: 200,
              interest: 25,
            },
          ],
          commision: 150,
          currentInstallment: {
            idDeferral: 1,
            principalBalance: 500,
            interest: 250,
            paymentValue: 560,
            dueDate: new Date(),
          },
        };
        setBalance(dummyData);
        if (loggedUser && loggedUser.eMail === "demo@demo.com") return setCustomer(loggedUser);
        return setCustomer(demoUser);
      }
      let response = await getToken();
      let validToken = response.data.token;
      const checkUser = async (user) => {
        if (user.idCustomer) {
          getStatus(idProduct, user.idCustomer, false, validToken).then((res) => {
            if (res.status && res.data.idStatus === 1) {
              if (res.data.idSubStatus === 180) return props.history.push("/registration/personal-details");
              if (res.data.idSubStatus === 181) return props.history.push("/registration/employment-details");
              if (res.data.idSubStatus === 182) return props.history.push("/registration/nip-bureau");
              if (res.data.idSubStatus === 183) return props.history.push("/registration/nip-bureau");
              if (res.data.idSubStatus === 184) return props.history.push("/registration/nip-bureau");
              if (res.data.idSubStatus === 185) return props.history.push("/registration/nip-bureau");
              if (res.data.idSubStatus === 195) return props.history.push("/registration-complete");
              if (res.data.idSubStatus === 196) return;
              if (res.data.idSubStatus === 203) return;
              if (res.data.idSubStatus === 206) return props.history.push("/application-review");
              if (res.data.idSubStatus === 217) return props.history.push("/dashboard/id");
              if (res.data.idSubStatus === 218) return props.history.push("/application-complete");
              if (res.data.idSubStatus === 219) return props.history.push("/application-complete");
              if (res.data.idSubStatus === 248) return props.history.push("/application-complete");

              if (res.data.idSubStatus === 270) return props.history.push("/loan-review");
              if (res.data.idSubStatus === 271) return props.history.push("/loan-review");
              if (res.data.idSubStatus === 273) return props.history.push("/loan-review");

              if (res.data.idSubStatus === 257) return props.history.push("/identity-validated");
            }
            if (res.status && res.data.idStatus === 4) {
              return props.history.push("/denied");
            } else return;
          });
        } else
          getStatus(idProduct, user.customerId, false, validToken).then((res) => {
            if (res.status && res.data.idStatus === 1) {
              if (res.data.idSubStatus === 180) return props.history.push("/registration/personal-details");
              if (res.data.idSubStatus === 181) return props.history.push("/registration/employment-details");
              if (res.data.idSubStatus === 182) return props.history.push("/registration/nip-bureau");
              if (res.data.idSubStatus === 183) return props.history.push("/registration/nip-bureau");
              if (res.data.idSubStatus === 184) return props.history.push("/registration/nip-bureau");
              if (res.data.idSubStatus === 185) return props.history.push("/registration/nip-bureau");
              if (res.data.idSubStatus === 195) return props.history.push("/registration-complete");
              if (res.data.idSubStatus === 196) return;
              if (res.data.idSubStatus === 203) return;
              //TODO: CHECK
              //if (res.data.idSubStatus === 206) return props.history.push("/application-review");
              if (res.data.idSubStatus === 217) return props.history.push("/dashboard/id");
              if (res.data.idSubStatus === 218) return props.history.push("/application-complete");
              if (res.data.idSubStatus === 219) return props.history.push("/application-complete");
              if (res.data.idSubStatus === 248) return props.history.push("/application-complete");
              if (res.data.idSubStatus === 257) return props.history.push("/identity-validated");
              if (res.data.idSubStatus === 270) return props.history.push("/loan-review");
              if (res.data.idSubStatus === 271) return props.history.push("/loan-review");
              if (res.data.idSubStatus === 273) return props.history.push("/loan-review");
            }
            if (res.status && res.data.idStatus === 4) {
              return props.history.push("/denied");
            } else return;
          });
      };

      const checkDocuments = async (user) => {
        const data = {
          idCustomer: user.customerId || user.idCustomer,
          idProduct,
          idAction: 1,
          customerChecked: false,
        };
        const {
          data: { showCheckBox },
        } = await getDocumentList(data, validToken);

        setShowCheckbox(showCheckBox);
        if (showCheckBox) {
          setInsurance(true);
        }
      };
      // let approved = sessionStorage.getItem('APP')
      // if(approved === 'no') return props.history.push('/denied')
      if (!loggedUser) {
        const emptyCustomer = await JSON.parse(sessionStorage.getItem("empty-customer"));
        if (emptyCustomer) {
          let data = {
            idProduct,
          };
          if (emptyCustomer.idCustomer) {
            data.idCustomer = emptyCustomer.idCustomer;
          } else data.idCustomer = emptyCustomer.customerId;
          checkUser(data);
          checkDocuments(data);
          setCustomer({
            customerId: data.idCustomer,
            eMail: emptyCustomer.eMail,
            mobile: emptyCustomer.mobile,
          });
          fillContract(data.idCustomer, validToken);
          return getBalance(data.idCustomer, validToken);
        }
        return loadLocalData();
      }
      setCustomer(loggedUser);
      checkUser(loggedUser);
      checkDocuments(loggedUser);
      fillContract(loggedUser.customerId, validToken);
      getBalance(loggedUser.customerId, validToken);
    };
    initialConfig();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // this flag is activated if the user is a repeater or it access to the SecondLoan screen.
    setAskPhoneRepeater(sessionStorage.getItem("ask_phone_repeater"));
    setNipEnteredRepeater(sessionStorage.getItem("nip_entered_repeater")); //This flag is only true when the user enters their NIP once is asked before the pre-approved screen.
  }, []);

  /**
   * This useEffect handles the behavior to ask the user for the NIP once they want to ask for a new loan.
   */
  useEffect(() => {
    if (loadedRepeater && askPhoneRepeater && !nipEnteredRepeater) {
      if (!sessionStorage.showNIPOnPreApproved) {
        sendSMS();
        sessionStorage.showNIPOnPreApproved = true;
        setShowNIP(true);
      }
    }
  }, [askPhoneRepeater, nipEnteredRepeater, loadedRepeater]);

  useEffect(() => {
    setShowNIP(sessionStorage.showNIPOnPreApproved ? true : false);
  }, []);

  //Interval to send sms
  useEffect(() => {
    if (!sentBySMS) {
      setTimeToSend(89);
      localStorage.setItem("timeToSendNIP", 89);
    } else {
      const interval = setInterval(() => {
        let at = parseInt(localStorage.getItem("timeToSendNIP")) || 89;
        at = at - 1;
        setTimeToSend(at);
        localStorage.setItem("timeToSendNIP", at);
        if (at === 0) {
          setSentBySMS(false);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [sentBySMS]);

  let fillDemo = () => {
    setLoading(false);
    setTermsAccepted(true);
  };

  useEffect(() => {
    setShowPromo(sessionStorage.returningClient === "RETURNINGSLEEPER");
  }, [sessionStorage.returningClient]);

  return (
    <>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-8 px-4 py-12 self-center">
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Resumen del crédito</h1>
            <h2 className="text-2xl lg:text-4xl text-green-light">Revisa los detalles de tu crédito antes de aceptar.</h2>
          </div>
          {showPromo &&
            // Date is before 1 of november
            moment.utc().isBefore(moment.utc("2023-11-01")) && (
              <div className="font-bold text-green-dark p-4 border border-green-dark w-full lg:w-2/3 flex items-center justify-center text-center flex-col ">
                <span className="text-xl">¡YA TIENES TU DESCUENTO! </span>
                <b className="">
                  <a href="/perfil" className="text-green-dark underline hover:text-green-light transition-all text-md">
                    IMPORTANTE: Solo haz click este enlace para actualizar tu cuenta bancaria y número de teléfono para activar el descuento.
                  </a>
                </b>
                <span className="text-green-dark text-sm">
                  Aplican restricciones, consulta los{" "}
                  <a href="/contenido/terminos-y-condiciones-promociones" className="text-green-dark underline hover:text-green-light transition-all">
                    {" "}
                    términos y condiciones
                  </a>
                  .
                </span>
              </div>
            )}
          <div className="flex flex-col bg-green-light w-full lg:w-2/3 lg:gap-4 rounded-xl">
            <div className="lg:flex grid grid-cols-2 items-center py-4 text-center gap-4 px-4 lg:px-12 justify-between">
              <div className="flex flex-col gap-2 items-center justify-center text-xl">
                <p className="font-bold text-white">Monto del préstamo</p>
                <p className="font-bold text-green-dark">
                  {balance.amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  <small> MXN</small>
                </p>
              </div>
              <div className="flex flex-col gap-2 items-center justify-center text-xl">
                <p className="font-bold text-white">Interés</p>
                <p className="font-bold text-green-dark">
                  ${balance.installments.reduce((acc, installment) => acc + installment.interest, 0).toFixed(2)}
                  <small> MXN</small>
                </p>
              </div>
              <div className="flex flex-col gap-2 items-center justify-center text-xl">
                <p className="font-bold text-white">Plazo del préstamo</p>
                <p className="font-bold text-green-dark">
                  {balance.term} {balance.idFrequency === 1 ? "semanas" : "días"}
                </p>
              </div>
              <div className="flex flex-col gap-2 items-center justify-center text-xl">
                <p className="font-bold text-white">Comisiones</p>
                <p className="font-bold text-green-dark">
                  {balance.commision.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}{" "}
                  <small>MXN</small>
                </p>
              </div>
            </div>
            <div className="lg:flex grid grid-cols-2 text-center gap-4 items-center py-4  px-4 lg:px-12 justify-around ">
              <div className="flex flex-col gap-2 items-center justify-center text-xl">
                <p className="font-bold text-white">Inicio del préstamo</p>
                <p className="font-bold text-green-dark">{momentEs(balance.startDate).format("D/MMM/Y")}</p>
              </div>
              <div className="flex flex-col gap-2 items-center justify-center text-xl">
                <p className="font-bold text-white">Fin del préstamo</p>
                <p className="font-bold text-green-dark">{momentEs(balance.installments[balance.installments.length - 1].dueDate).format("D/MMM/Y")}</p>
              </div>
              <div className="flex flex-col gap-2 items-center justify-center text-xl">
                <p className="font-bold text-white">Monto total a pagar</p>
                <p className="font-bold text-green-dark">
                  {(balance.currentInstallment.paymentValue + (insurance ? 150 : 0)).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  <small> MXN</small>
                </p>
              </div>
            </div>
            <div className="flex flex-col p-4 items-center justify-center">
              <Link to="/dashboard" className="text-white font-bold underline cursor-pointer">
                Modificar solicitud de préstamo
              </Link>
              <small className="text-white text-sm text-center">El préstamo está sujeto a aprobación y verificación de crédito</small>
            </div>
          </div>
          <div className="flex flex-col gap-4  w-full lg:w-2/3 p-4">
            {showCheckbox && (
              <div className="flex w-full gap-4 items-center">
                <CheckBox
                  label=""
                  onChange={() => {
                    setInsurance(!insurance);
                  }}
                  checked={insurance}
                />
                <p>Acepto contratar seguro de vida mensual por $150 pesos más IVA</p>
              </div>
            )}

            <div className="flex w-full gap-4 items-center">
              <CheckBox
                label=""
                onChange={() => {
                  setOpen(true);
                }}
                checked={termsAccepted}
              />
              <p>
                Acepto los términos y condiciones del contrato.{" "}
                {loading ? (
                  <span>Cargando contrato...</span>
                ) : (
                  <span className="cursor-pointer text-gray-800 underline font-bold" rel="noopener noreferrer" onClick={() => setOpen(true)}>
                    Ver contrato
                  </span>
                )}
              </p>
            </div>
            <div className="flex w-full gap-4 items-center">
              <CheckBox
                label=""
                onChange={() => {
                  setAuthorizationAddressing(!authorizationAddressing);
                }}
                checked={authorizationAddressing}
              />
              <p>Autorizo que la empresa que designe VIVUS domicilie el pago de mi crédito simple vigente, en la fecha y por el monto total a pagar señalado a continuación.</p>
            </div>

            {!showNIP && (
              <Button className={`lg:w-36 ${termsAccepted && authorizationAddressing && !continueDisabled ? "" : "pointer-events-none bg-gray-200 text-gray-800"} `} onClick={termsAccepted && authorizationAddressing ? goTo : () => window.scrollTo(0, 0)}>
                {loadingConfirm ? <BallClipRotate loading color={"white"} /> : !termsAccepted ? "DESCARGA EL CONTRATO" : "CONTINUAR"}
              </Button>
            )}
            {serverError ? (
              <p className="text-red-500">
                Ocurrió un error, inténtalo nuevamente <br />
                (400, bad request: setContractAuthorization)
              </p>
            ) : null}
            {directDebitError ? (
              <p className="text-red-500">
                Ocurrió un error, inténtalo nuevamente <br />
                (400, bad request: directDebit)
              </p>
            ) : null}
          </div>

          {showNIP && (
            <div className="flex flex-col gap-4  w-full lg:w-2/3 p-4">
              <div className="flex flex-col items-start justify-items-start gap-4">
                <h3 className="font-bold text-2xl">¡Tu préstamo está aprobado! </h3>
                {changeNumberMode && (
                  <>
                    <p className="">Por favor, ingresa un número de teléfono al que podamos contactarte para enviar el código de verificación.</p>
                    <div className={`flex flex-col gap-2 w-full`}>
                      <label className="font-bold">Nuevo número de teléfono</label>
                      <input className="h-10 w-full bg-white rounded-xl px-4 lg:w-96 text-gray-800 border border-gray-200 shadow-sm" onChange={(e) => setPhone(e.target.value)} type="text" maxLength="10" value={phone} />
                      {phoneError ? <span className="text-red-500">Ingresa un número de teléfono válido.</span> : null}
                      {phoneServerError ? <span className="text-red-500">Hubo un error al actualizar tu teléfono, recarga la página e inténtalo nuevamente.</span> : null}

                      <small onClick={() => setChangeNumberMode(false)} className="underline text-green-dark cursor-pointer">
                        Cancelar cambio de teléfono
                      </small>
                    </div>
                    <Button className={`lg:w-56 text-center ${authorized ? "" : "bg-gray-50 cursor-default"}`} onClick={handleChangeNumber}>
                      {loading ? <BallBeat color={"#fff"} loading /> : "CAMBIAR NÚMERO"}
                    </Button>
                  </>
                )}
                {!changeNumberMode && (
                  <>
                    <p className="text-green-dark text-xl">
                      Para terminar, coloca el NIP de 4 dígitos que llegó a tu teléfono con número <strong>{phone || customer?.mobile}</strong>, al hacerlo, ya no te llamaremos y depositaremos tu préstamo el día de hoy.{" "}
                      <i className="cursor-pointer underline font-bold text-xl text-green-dark">
                        <strong onClick={() => setChangeNumberMode(true)}>¿Este no es tu número?</strong>
                      </i>
                    </p>

                    <div className={`flex flex-col gap-2`}>
                      <label className="font-bold">NIP</label>
                      <input className="h-10 w-full bg-white rounded-xl px-4 lg:w-96 text-gray-800 border border-gray-200 shadow-sm" onChange={(e) => setNip(e.target.value)} type="text" maxLength="4" value={nip} />
                      {nipError ? <span className="text-red-500">NIP incorrecto.</span> : null}
                      <small>Sólo espera un momento para recibir nuestro mensaje de texto.</small>
                      <small>
                        Si no lo has recibido, puedes volver a solicitarlo{" "}
                        {sentBySMS ? (
                          <strong>en {timeToSend} segundos</strong>
                        ) : (
                          <strong onClick={() => (phoneChanged ? sendNewSMS(phone) : sendSMS())} className="text-green-light cursor-pointer underline">
                            Dando click aquí
                          </strong>
                        )}
                      </small>
                    </div>
                    <Button
                      className={`lg:w-64 ${authorized && termsAccepted && authorizationAddressing ? "" : "pointer-events-none bg-gray-200 text-gray-800"}`}
                      onClick={async () => {
                        if (authorized && termsAccepted && authorizationAddressing) {
                          handleNIP();
                        }
                      }}
                    >
                      {loading ? <BallBeat loading color={"white"} /> : "DEPOSITEN MI PRESTAMO AHORA"}
                    </Button>
                  </>
                )}
                <p className="lg:text-sm text-xs text-gray-500">
                  Por este medio, declaro bajo protesta de decir verdad, que la información proporcionada a DIFINANCE , S.A. de C.V., SOFOM, E.N.R. en este formato electrónico es verdadera y precisa, por este medio libero a DIFINANCE , S.A. de C.V., SOFOM, E.N.R. de cualquier responsabilidad en
                  relación con la misma. Por este medio autorizo a DIFINANCE , S.A. de C.V., SOFOM, E.N.R. para realizar mediante sus representantes o cualquier otra persona autorizada para tal propósito, cualquier investigación sobre mi historial crediticio o del historial crediticio de la persona
                  que por este medio represento ante Círculo de Crédito S.A. de C.V. Sociedad de Información Crediticia y/o Trans Unión de México S.A. Sociedad de Información Crediticia. Además, por este medio declaro que estoy consciente del alcance de las actividades realizadas por las sociedades
                  de información crediticia y del uso y propósitos de la información crediticia que obtendrá DIFINANCE , S.A. de C.V., SOFOM, E.N.R. como se refleja en el reporte de crédito correspondiente. Por este medio, consiento el estar vinculado por esta autorización durante un año contado a
                  partir de que otorgo mi consentimiento en esta fecha o hasta dos años en caso de autorizarlo y/o, durante el tiempo en que la relación jurídica con DIFINANCE , S.A. de C.V., SOFOM, E.N.R. sea válida. Por este medio, consiento que DIFINANCE , S.A. de C.V., SOFOM, E.N.R., Círculo de
                  Créditos S.A. de C.V: Sociedad de Información Crediticia y/o Trans Unión de México S.A., Sociedad de Información Crediticia tendrán en custodia este documento de acuerdo con el artículo 28 de la ley para regular las sociedades de información crediticia.
                </p>
                <p>
                  <strong>El cliente autoriza a DIFINANCE , S.A. de C.V., SOFOM, E.N.R. la utilización de medios electrónicos de autentificación, tales como el NIP.</strong>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {open && <ContractModal contract={contract} onClose={() => setOpen(false)} isPDF={true} />}
    </>
  );
};

export default withRouter(Cargo);
