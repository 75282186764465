import React from "react";
import { useLoginPopUp } from "../../hooks/use-login-popup";
import Button from "./Button";
import moment from "moment";

const GeneralPopup = () => {
  const { setPopupShown, showPopup } = useLoginPopUp();
  return (
    <>
      {/* Show only of showPopup is true and date is before 28/11/2023 */}
      {showPopup && moment.utc().isBefore(moment.utc("2023-11-28")) ? (
        <div className="bg-black bg-opacity-50 fixed h-full w-full flex top-0 left-0 items-center justify-center p-4 md:px-20 z-30 text-[8pt] overflow-auto" onClick={() => setPopupShown(true)}>
          <div
            className="bg-white max-h-[90%] lg:max-w-[900px] max-w-full overflow-auto flex flex-col"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="overflow-auto flex-1 p-4 px-12 flex flex-col gap-1">
              <p className="text-sm">Fecha: 20 de octubre de 2023</p>
              <p className="mt-12">Estimado Cliente,</p>

              <p>Nos complace informarle que en DIFINANCE S.A. DE C.V., SOFOM E.N.R. hemos realizado modificaciones al Contrato de Adhesión que rige su producto o servicio financiero de crédito simple VIVUS. </p>
              <p>Los cambios realizados al Contrato de Adhesión incluyen:</p>
              <p>
                Se modifica la metodología del cálculo de interés a 360 días (esto sólo en la redacción del contrato ya que a su crédito ya se aplica de esta forma), se adiciona proceso para consulta de saldos y movimientos, se agrega declaración del usuario para consulta de historial crediticio, se
                adicionan derechos de los usuarios.
              </p>
              <p>
                A la carátula se adiciona los cuadros de seguros de acuerdo con las disposiciones aplicables, sin embargo, esto no es aplicable al contrato de VIVUS. Es importante mencionar que esos cambios no tienen un impacto directo en cómo usted utiliza su crédito, pero se adicionaron para mayor
                transparencia en los servicios que brinda VIVUS.{" "}
              </p>
              <p className="!my-4">
                Usted puede consultar el contrato en{" "}
                <a href="https://registros.condusef.gob.mx/reca/_index.php" target="_blank" className="text-green-dark">
                  https://registros.condusef.gob.mx/reca/_index.php.
                </a>
              </p>
              <p>
                Estos cambios se implementarán a su crédito a partir de los 30 (treinta) días naturales posteriores a la fecha de este aviso por lo que, de acuerdo con la normativa vigente, usted tiene el derecho de solicitar la terminación del Contrato de Adhesión dentro de los treinta días
                posteriores a la fecha de este aviso sin incurrir en ninguna responsabilidad. Las condiciones pactadas originalmente se mantendrán, y deberá cubrir, en su caso, los adeudos que se generen hasta el término de la operación o servicio. La Entidad Financiera no podrá cobrarle
                penalización alguna por esta causa.
              </p>
              <p>Si tiene alguna pregunta o necesita más información sobre las modificaciones realizadas, no dude en ponerse en contacto con nuestro equipo de atención al cliente en la dirección, número de teléfono y correo electrónico proporcionados a continuación.</p>
              <p>
                Domicilio y teléfono de contacto: <br />
                (55) 6717 0750
                <br />
                info@vivus.com.mx
                <br />
                Nuestro horario de atención al cliente es: lunes a viernes de 8:00 a 20:00 / Sábado de 8:00 a 14:00
                <br />
              </p>
              <p>
                <b>UNE de Atención a Usuarios</b>
                <br />
                Titular de la UNE: FERNANDO DEL RAZO LÓPEZ
                <br />
                Domicilio: CERES NO. 23, COLONIA CRÉDITO CONSTRUCTOR C.P. 03940 ALCALDÍA/MUNICIPIO/ BÉNITO JUÁREZ ENTIDAD FEDERATIVA: CIUDAD DE MÉXICO, Referencia: NOTARIAS 6 Y 142 ENTRE CALLE AV. INSURGENTES SUR Y CALLE MINERVA.
                <br />
                Horario de atención: Lunes a viernes de 9:00 a 17:00 Hrs.
                <br />
                Teléfono y correo electrónico: 5585266148 | une@digitalfinance.com
                <br />
              </p>
              <p>Agradecemos su confianza en DIFINANCE S.A. DE C.V., SOFOM E.N.R. Estamos comprometidos en brindarle el mejor servicio y transparencia en todas nuestras operaciones financieras.</p>
              <p>Atentamente, DIFINANCE S.A. DE C.V., SOFOM E.N.R.</p>
            </div>
            <div className="flex w-full items-center justify-center py-4">
              <Button onClick={() => setPopupShown(true)}>Cerrar</Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

GeneralPopup.propTypes = {};
GeneralPopup.defaultValues = {};

export default GeneralPopup;
