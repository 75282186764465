import React, { useState } from "react";
import { getToken, changeCLABE } from "../../../../services/api";
import Button from "../../../common/Button";

const idProduct = 1;

const PaymentBank = ({ setSection }) => {
  const [clabe, setClabe] = useState("");
  const [clabeErr, setClabeErr] = useState(false);
  const [currentChange, setCurrentChange] = useState(false);
  const [success, setSuccess] = useState(false);

  const checkClabe = async () => {
    let response = await getToken();
    if (!response) return setClabeErr(true);
    let validToken = response.data.token;
    if (!clabe) return setClabeErr(true);
    let regEx = /^([0-9]{18})$/;
    const user = JSON.parse(sessionStorage.getItem("loggedUser"));
    const data = {
      clabeAccount: clabe,
      customerIP: sessionStorage.getItem("ip") || "0.0.0.0",
      coordinates: sessionStorage.getItem("coords") || "location blocked by user",
      userAgent: window.navigator.userAgent,
      idCustomer: user.customerId,
      idProduct,
    };
    if (regEx.test(clabe)) {
      setClabeErr(false);
      changeCLABE(data, validToken)
        .then((res) => {
          setSuccess(true);
        })
        .catch((err) => {
          if (err.response.status === 403) return setCurrentChange(true);
          else setClabeErr(true);
        });
    } else {
      setClabeErr(true);
    }
  };

  return (
    <div className="lg:max-w-xl">
      {success ? (
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl">Listo, el cambio de tu CLABE está en trámite</h3>
          <p className="text-gray-500">Realizaremos una validación y en breve te informaremos el resultado, espéralo en tu correo electrónico en un plazo de 24 horas hábiles</p>
          <p className="text-red-500">
            Al continuar, acepto que este nuevo número es válido para actualizar mi formato de autorización de cargo automático por el monto de las parcialidades que en su caso tenga pendientes. CLABE número: <br />
            {clabe}
          </p>
          <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
            <p>Regresar</p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl">Cuenta bancaria</h3>

          <div className={`flex flex-col gap-2`}>
            <label className="font-bold">CLABE (18 dígitos)</label>
            <input className="h-10 w-full bg-white rounded-xl px-4 text-gray-800 border border-gray-200 shadow-sm lg:w-96" maxLength="18" onChange={(e) => setClabe(e.target.value)} value={clabe} type="text" />
            {currentChange ? <span className="text-red-500">Ya tienes un trámite en proceso</span> : null}
            {clabeErr ? <span className="text-red-500">CLABE incorrecta</span> : null}
            <small>
              La CLABE es un número de 18 dígitos que generan los Bancos para poder realizar transferencias electrónicas. Puedes localizarlo en cualquier estado de cuenta, contrato o puedes llamar a tu banco para obtenerlo. Si no lo tienes, no se captura correctamente o no está a TU NOMBRE, no
              podremos realizar el depósito.
            </small>
          </div>
          <Button className={`lg:w-96 text-center`} onClick={checkClabe}>
            GUARDAR
          </Button>
          <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
            <p>Regresar</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentBank;
